import styles from "./PatientFacility.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { Calendar } from "react-date-range";
import { addDays, format } from "date-fns";
import { setPRFacilityInfo, setRegistrationStatus } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { getFacilityFormEdit, getFacilityInfoSelector, getPatientInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import { setFacilityFormEdit } from "../../../../Actions";

const PatientFacility = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();

    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const facilityInfo = useSelector(getFacilityInfoSelector);

    const patientInfoObj = useSelector(getPatientInfoSelector);

    const facilityName = patientInfoObj.facilityName ? patientInfoObj.facilityName : '';
    const facilityNum = patientInfoObj.facilityKey ? patientInfoObj.facilityKey : '';
    const facilityType = patientInfoObj.facilityType ? patientInfoObj.facilityType : '';

    const [bedNum, setBedNum] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.bed ? patientInfoObj.subjectMore.facility.bed : '' : '' : '');
    const [actCode, setActCode] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.code ? patientInfoObj.subjectMore.facility.code : '' : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.code ? patientInfoObj.subjectMore.facility.code : '' : '' : '' }]);
    const [unit, setUnit] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.unit ? patientInfoObj.subjectMore.facility.unit : '' : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.unit ? patientInfoObj.subjectMore.facility.unit : '' : '' : '' }]);
    const [floor, setFloor] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.floor ? patientInfoObj.subjectMore.facility.floor : '' : '' : '');
    const [roomNum, setRoomNum] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.room ? patientInfoObj.subjectMore.facility.room : '' : '' : '');
    const [admDate, setAdmDate] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.facility ? patientInfoObj.subjectMore.facility.entryDate ? new Date(patientInfoObj.subjectMore.facility.entryDate).toISOString().split('T')[0] : '' : '' : '');

    const [isCalOpen, setIsCalOpen] = useState(false);

    const refOne = useRef(null);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isActCodeFill, setIsActCodeFill] = useState(true);
    const [isFloorFill, setIsFloorFill] = useState(true);
    const [isRoomNumFill, setIsRoomNumFill] = useState(true);

    const isFacilityFormEditable = useSelector(getFacilityFormEdit);

    const resetReqFills = () => {
        setIsActCodeFill(true);
        setIsFloorFill(true);
        setIsRoomNumFill(true);
    };

    const clearAllFacilityFields = () => {
        setBedNum('');
        setActCode([]);
        setUnit([]);
        setFloor('');
        setRoomNum('');
        setAdmDate(new Date());
        resetReqFills();
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllFacilityFields: clearAllFacilityFields,
        };
    });

    const optionsActCode = [
        { value: 'Initial Admission (IA)', label: 'Initial Admission (IA)' },
        { value: 'Readmission (RA)', label: 'Readmission (RA)' },
        { value: 'Respite - Initial Admission (RIA)', label: 'Respite - Initial Admission (RIA)' },
        { value: 'Respite - ReAdmission (RRA)', label: 'Respite - ReAdmission (RRA)' },
    ];

    const optionsUnit = [
        { value: 'North', label: 'North' },
        { value: 'South', label: 'South' },
        { value: 'East', label: 'East' },
        { value: 'West', label: 'West' },
    ];

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label && state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
    }, []);

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            handleCalClick(e);
        }
    };

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            handleCalClick(e);
        }
    };

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };


    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: curRegStatus.step4,
            step5: true,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {
        const payload = {
            facilityName: facilityName,
            facilityNum: facilityNum,
            facilityType: facilityType,
            bedNum: bedNum,
            actCode: actCode[0] ? actCode[0].value : '',
            unit: unit[0] ? unit[0].value : '',
            floor: floor,
            roomNum: roomNum,
            admDate: format(admDate, "yyyy-MM-dd"),
        };
        //console.log("facilityInfo payload: ", payload);
        dispatch(setPRFacilityInfo(payload));
    };

    const checkIfPageConditionsMet = () => {
        if ((floor !== '') && (actCode[0] && actCode[0].value !== '') && (roomNum !== '')) {
            //console.log('conditions met!');
            resetReqFills();
            setShowReqWarn(false);
            return true;
        } else {
            //console.log('conditions not met!');
            resetReqFills();
            if (floor === '') {
                setIsFloorFill(false);
            };
            if (roomNum === '') {
                setIsRoomNumFill(false);
            };
            if (!actCode[0] || actCode[0].value === '') {
                setIsActCodeFill(false);
            };
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSaveAndContinue = () => {
        if (checkIfPageConditionsMet()) {
            //console.log('conditions met!');
            props.tabChange("6");
            setTabInfo();
            markStepCompleted();
        }
    };

    const handleBackCLick = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: false,
            step5: false,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        props.tabChange("4");
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const editFacilityForm = (event) => {
        event.preventDefault();
        dispatch(setFacilityFormEdit(true));
    };

    const cancelEdit = () => {
        dispatch(setFacilityFormEdit(false));
    };

    const handleSaveFacility = () => {

    };

    return (
        <>
            <div className={styles.containerDiv}>
                <div className={styles.dumDiv}>
                    <div className={styles.headerDiv}>
                        <div></div>
                        <div>Facility Information</div>
                        <div className={styles.editButtonDiv}>
                            {/* <div className={!isFacilityFormEditable ? styles.editButton : styles.editButtonClicked} onClick={event => editFacilityForm(event)}>Edit</div> */}
                        </div>
                    </div>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Facility Name
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={facilityName} disabled={true} className={styles.inputFieldDisabled} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Facility Number
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="number" value={facilityNum} disabled={true} className={styles.inputFieldDisabled} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Facility Type
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={facilityType} disabled={true} className={styles.inputFieldDisabled} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Action Code
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={styles.dropdownDisabled2}
                                        values={actCode}
                                        onChange={setActCode}
                                        options={optionsActCode}
                                        color="#4E253A"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Admission Date (YYYY-MM-DD)
                                </div>
                                <div className={styles.fieldInput}>
                                    {/* <input
                                        value={`${format(admDate, "yyyy/MM/dd")}`}
                                        readOnly
                                        className={styles.inputFieldSelctedDOB}
                                        onClick={(event) => handleCalClick(event)}
                                    />
                                    {isCalOpen && (
                                        <div className={styles.datePickerWrapper} ref={refOne}>
                                            <Calendar
                                                onChange={item => setAdmDate(item)}
                                                editableDateInputs={false}
                                                showSelectionPreview={true}
                                                months={1}
                                                date={admDate}
                                                direction="horizontal"
                                                maxDate={addDays(new Date(), 1)}
                                                color={["#4E253A"]}
                                            />
                                            <div className={styles.applyButtonDiv}>
                                                <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                            </div>
                                        </div>
                                    )} */}
                                    <div className={styles.fieldInput}>
                                        <input type="text" disabled={true} className={styles.inputFieldDisabled} value={admDate} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.column3}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Floor <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={floor} onChange={(e) => setFloor(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Room Number <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={roomNum} onChange={(e) => setRoomNum(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column4}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Unit/Wing/Zone <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={isFacilityFormEditable ? styles.dropdown2E : styles.dropdown2}
                                        disabled={!isFacilityFormEditable}
                                        values={unit}
                                        onChange={setUnit}
                                        options={optionsUnit}
                                        color="#4E253A"
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Bed Number <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={bedNum} onChange={(e) => setBedNum(e.currentTarget.value)} disabled={!isFacilityFormEditable} className={isFacilityFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divFooter}>
                        {/* {showReqWarn && <div className={styles.consentWarningDiv}>
                            Please fill out the required fields marked with an *
                        </div>} */}
                        {isFacilityFormEditable && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSaveFacility}>Save</div>
                        </div>)}
                    </div>
                </div>
            </div>
        </>
    );
});

export default PatientFacility;