import { createAction } from "@reduxjs/toolkit";
import { patientConstants } from "../Constants/PatientConstants";

export const getPatientsList = createAction(
  patientConstants.GET_PATIENTS_LIST,
  (payload) => ({ payload })
);

export const getPatientsListSuccess = createAction(
  patientConstants.GET_PATIENTS_LIST_SUCCESS,
  (payload) => ({ payload })
);

export const getPatientVitalsList = createAction(
  patientConstants.GET_PATIENT_VITALS_LIST,
  (payload) => ({ payload })
);

export const getPatientsVitalsSuccess = createAction(
  patientConstants.GET_PATIENT_VITALS_LIST_SUCCESS,
  (payload) => ({ payload })
);

export const getSubjectVitalsList = createAction(
  patientConstants.GET_SUBJECT_VITALS_LIST,
  (payload) => ({ payload })
);

export const getSubjectVitalsListSuccess = createAction(
  patientConstants.GET_SUBJECT_VITALS_LIST_SUCCESS,
  (payload) => ({ payload })
);

export const getEcgPlot = createAction(
  patientConstants.GET_ECG_PLOT,
  (payload) => ({ payload })
);

export const getEcgPlotSuccess = createAction(
  patientConstants.GET_ECG_PLOT_SUCCESS,
  (payload) => ({ payload })
);

export const getRfmPlot = createAction(
  patientConstants.GET_RFM_PLOT,
  (payload) => ({ payload })
);

export const getRfmPlotSuccess = createAction(
  patientConstants.GET_RFM_PLOT_SUCCESS,
  (payload) => ({ payload })
);

export const getLfgPlot = createAction(
  patientConstants.GET_LFG_PLOT,
  (payload) => ({ payload })
);

export const getLfgPlotSuccess = createAction(
  patientConstants.GET_LFG_PLOT_SUCCESS,
  (payload) => ({ payload })
);

export const getMouthLabLogs = createAction(
  patientConstants.GET_MOUTHLAB_LOGS,
  (payload) => ({ payload })
);

export const getMouthLabLogSuccess = createAction(
  patientConstants.GET_MOUTHLAB_LOGS_SUCCESS,
  (payload) => ({ payload })
);

export const getAddSubject = createAction(
  patientConstants.GET_ADD_SUBJECT,
  (payload) => ({ payload })
);

export const getAddSubjectSuccess = createAction(
  patientConstants.GET_ADD_SUBJECT_SUCCESS,
  (payload) => ({ payload })
);

export const getLatestBaseline = createAction(
  patientConstants.GET_LATEST_BASELINE,
  (payload) => ({ payload })
);

export const getLatestBaselineSuccess = createAction(
  patientConstants.GET_LATEST_BASELINE_SUCCESS,
  (payload) => ({ payload })
);

export const getSubectVitalsListFailure = createAction(patientConstants.GET_SUBJECT_VITALS_LIST_FAILURE);

export const getPatientsVitalsFailure = createAction(patientConstants.GET_PATIENT_VITALS_LIST_FAILURE);

export const getLatestBaselineFailure = createAction(patientConstants.GET_LATEST_BASELINE_FAILURE);

export const getPatientsListFailure = createAction(patientConstants.GET_PATIENTS_LIST_FAILURE);

export const getEcgPlotFailure = createAction(patientConstants.GET_ECG_PLOT_FAILURE);

export const getRfmPlotFailure = createAction(patientConstants.GET_RFM_PLOT_FAILURE);

export const getLfgPlotFailure = createAction(patientConstants.GET_LFG_PLOT_FAILURE);

export const getMouthLabLogsFailure = createAction(patientConstants.GET_MOUTHLAB_LOGS_FAILURE);

export const getAddSubjectFailure = createAction(patientConstants.GET_ADD_SUBJECT_FAILURE);

export const setTimerStatus = createAction(patientConstants.SET_TIMER_STATUS);

export const setTimerCount = createAction(patientConstants.SET_TIMER_COUNT);

export const putStartTimer = createAction(
  patientConstants.PUT_START_TIMER,
  (payload) => ({ payload })
);

export const putStartTimerSuccess = createAction(
  patientConstants.PUT_START_TIMER_SUCCESS,
  (payload) => ({ payload })
);

export const putStartTimerFailure = createAction(patientConstants.PUT_START_TIMER_FAILURE);

export const putEndTimer = createAction(
  patientConstants.PUT_END_TIMER,
  (payload) => ({ payload })
);

export const putEndTimerSuccess = createAction(
  patientConstants.PUT_END_TIMER_SUCCESS,
  (payload) => ({ payload })
);

export const putEndTimerFailure = createAction(patientConstants.PUT_END_TIMER_FAILURE);

export const putAbortedEndTimer = createAction(
  patientConstants.PUT_ABORT_END_TIMER,
  (payload) => ({ payload })
);

export const putAbortedEndTimerSuccess = createAction(
  patientConstants.PUT_ABORT_END_TIMER_SUCCESS,
  (payload) => ({ payload })
);

export const putAbortedEndTimerFailure = createAction(patientConstants.PUT_ABORT_END_TIMER_FAILURE);

export const setRrEdit = createAction(
  patientConstants.SET_RR_EDIT,
  (payload) => ({ payload })
);

export const setFev1Edit = createAction(
  patientConstants.SET_FEV1_EDIT,
  (payload) => ({ payload })
);

export const setPefEdit = createAction(
  patientConstants.SET_PEF_EDIT,
  (payload) => ({ payload })
);

export const setSugarEdit = createAction(
  patientConstants.SET_SUGAR_EDIT,
  (payload) => ({ payload })
);

export const setPainEdit = createAction(
  patientConstants.SET_PAIN_EDIT,
  (payload) => ({ payload })
);

export const setWeightEdit = createAction(
  patientConstants.SET_WEIGHT_EDIT,
  (payload) => ({ payload })
);

export const setTempEdit = createAction(
  patientConstants.SET_TEMP_EDIT,
  (payload) => ({ payload })
);

export const setSbpEdit = createAction(
  patientConstants.SET_SBP_EDIT,
  (payload) => ({ payload })
);

export const setDbpEdit = createAction(
  patientConstants.SET_DBP_EDIT,
  (payload) => ({ payload })
);

export const setPrEdit = createAction(
  patientConstants.SET_PR_EDIT,
  (payload) => ({ payload })
);

export const setSpo2Edit = createAction(
  patientConstants.SET_SPO2_EDIT,
  (payload) => ({ payload })
);

export const setHrEdit = createAction(
  patientConstants.SET_HR_EDIT,
  (payload) => ({ payload })
);

export const setHrvEdit = createAction(
  patientConstants.SET_HRV_EDIT,
  (payload) => ({ payload })
);

export const setAllEdit = createAction(
  patientConstants.SET_ALL_EDIT,
  (payload) => ({ payload })
);

export const setDisableAllEditButton = createAction(
  patientConstants.SET_DISABLE_ALL_EDIT_BUTTON,
  (payload) => ({ payload })
);

export const getSbpPayload = createAction(
  patientConstants.GET_SBP_PAYLOAD,
  (payload) => ({ payload })
);

export const getSbpPayloadSuccess = createAction(
  patientConstants.GET_SBP_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getSbpPayloadFailure = createAction(patientConstants.GET_SBP_PAYLOAD_FAILURE);

export const getDbpPayload = createAction(
  patientConstants.GET_DBP_PAYLOAD,
  (payload) => ({ payload })
);

export const getDbpPayloadSuccess = createAction(
  patientConstants.GET_DBP_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getDbpPayloadFailure = createAction(patientConstants.GET_DBP_PAYLOAD_FAILURE);


export const getSpo2Payload = createAction(
  patientConstants.GET_SPO2_PAYLOAD,
  (payload) => ({ payload })
);

export const getSpo2PayloadSuccess = createAction(
  patientConstants.GET_SPO2_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getSpo2PayloadFailure = createAction(patientConstants.GET_SPO2_PAYLOAD_FAILURE);


export const getBrPayload = createAction(
  patientConstants.GET_BR_PAYLOAD,
  (payload) => ({ payload })
);

export const getBrPayloadSuccess = createAction(
  patientConstants.GET_BR_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getBrPayloadFailure = createAction(patientConstants.GET_BR_PAYLOAD_FAILURE);


export const getHrPayload = createAction(
  patientConstants.GET_HR_PAYLOAD,
  (payload) => ({ payload })
);

export const getHrPayloadSuccess = createAction(
  patientConstants.GET_HR_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getHrPayloadFailure = createAction(patientConstants.GET_HR_PAYLOAD_FAILURE);


export const getHrvPayload = createAction(
  patientConstants.GET_HRV_PAYLOAD,
  (payload) => ({ payload })
);

export const getHrvPayloadSuccess = createAction(
  patientConstants.GET_HRV_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getHrvPayloadFailure = createAction(patientConstants.GET_HRV_PAYLOAD_FAILURE);


export const getTempPayload = createAction(
  patientConstants.GET_TEMP_PAYLOAD,
  (payload) => ({ payload })
);

export const getTempPayloadSuccess = createAction(
  patientConstants.GET_TEMP_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getTempPayloadFailure = createAction(patientConstants.GET_TEMP_PAYLOAD_FAILURE);


export const getPrPayload = createAction(
  patientConstants.GET_PR_PAYLOAD,
  (payload) => ({ payload })
);

export const getPrPayloadSuccess = createAction(
  patientConstants.GET_PR_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getPrPayloadFailure = createAction(patientConstants.GET_PR_PAYLOAD_FAILURE);


export const getFevPayload = createAction(
  patientConstants.GET_FEV_PAYLOAD,
  (payload) => ({ payload })
);

export const getFevPayloadSuccess = createAction(
  patientConstants.GET_FEV_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getFevPayloadFailure = createAction(patientConstants.GET_FEV_PAYLOAD_FAILURE);


export const getPefPayload = createAction(
  patientConstants.GET_PEF_PAYLOAD,
  (payload) => ({ payload })
);

export const getPefPayloadSuccess = createAction(
  patientConstants.GET_PEF_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getPefPayloadFailure = createAction(patientConstants.GET_PEF_PAYLOAD_FAILURE);

export const getGlucosePayload = createAction(
  patientConstants.GET_GLUCOSE_PAYLOAD,
  (payload) => ({ payload })
);

export const getGlucosePayloadSuccess = createAction(
  patientConstants.GET_GLUCOSE_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getGlucosePayloadFailure = createAction(patientConstants.GET_GLUCOSE_PAYLOAD_FAILURE);


export const getWeightPayload = createAction(
  patientConstants.GET_WEIGHT_PAYLOAD,
  (payload) => ({ payload })
);

export const getWeightPayloadSuccess = createAction(
  patientConstants.GET_WEIGHT_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getWeightPayloadFailure = createAction(patientConstants.GET_WEIGHT_PAYLOAD_FAILURE);


export const getPainPayload = createAction(
  patientConstants.GET_PAIN_PAYLOAD,
  (payload) => ({ payload })
);

export const getPainPayloadSuccess = createAction(
  patientConstants.GET_PAIN_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getPainPayloadFailure = createAction(patientConstants.GET_PAIN_PAYLOAD_FAILURE);


export const setNewThreshold = createAction(
  patientConstants.SET_NEW_THRESHOLD,
  (payload) => ({ payload })
);

export const setNewThresholdSuccess = createAction(
  patientConstants.SET_NEW_THRESHOLD_SUCCESS,
  (payload) => ({ payload })
);

export const setNewThresholdFailure = createAction(patientConstants.SET_NEW_THRESHOLD_FAILURE);


export const setBpComputed = createAction(
  patientConstants.SET_BP_COMPUTED,
  (payload) => ({ payload })
);

export const setSpo2BrComputed = createAction(
  patientConstants.SET_SPO2_BR_COMPUTED,
  (payload) => ({ payload })
);

export const setHrHrvComputed = createAction(
  patientConstants.SET_HR_HRV_COMPUTED,
  (payload) => ({ payload })
);

export const setTempPrComputed = createAction(
  patientConstants.SET_TEMP_PR_COMPUTED,
  (payload) => ({ payload })
);

export const setFevPefComputed = createAction(
  patientConstants.SET_FEV_PEF_COMPUTED,
  (payload) => ({ payload })
);

export const setGwpComputed = createAction(
  patientConstants.SET_GWP_COMPUTED,
  (payload) => ({ payload })
);

export const setShowConfirmationBanner = createAction(
  patientConstants.SET_SHOW_CONFIRMATION_BANNER,
  (payload) => ({ payload })
);


export const registerNewPatient = createAction(
  patientConstants.REGISTER_NEW_PATIENT,
  (payload) => ({ payload })
);

export const registerNewPatientSuccess = createAction(
  patientConstants.REGISTER_NEW_PATIENT_SUCCESS,
  (payload) => ({ payload })
);

export const registerNewPatientFailure = createAction(patientConstants.REGISTER_NEW_PATIENT_FAILURE);


export const getFacilityInfo = createAction(
  patientConstants.GET_FACILITY_INFO,
  (payload) => ({ payload })
);

export const getFacilityInfoSuccess = createAction(
  patientConstants.GET_FACILITY_INFO_SUCCESS,
  (payload) => ({ payload })
);

export const getFacilityInfoFailure = createAction(patientConstants.GET_FACILITY_INFO_FAILURE);

export const getIfEmailAlreadyExists = createAction(
  patientConstants.GET_IF_EMAIL_ALREADY_EXISTS,
  (payload) => ({ payload })
);

export const getIfEmailAlreadyExistsSuccess = createAction(
  patientConstants.GET_IF_EMAIL_ALREADY_EXISTS_SUCCESS,
  (payload) => ({ payload })
);

export const getIfEmailAlreadyExistsFailure = createAction(patientConstants.GET_IF_EMAIL_ALREADY_EXISTS_FAILURE);

export const getPatientInfo = createAction(
  patientConstants.GET_PATIENT_INFO,
  (payload) => ({ payload })
);

export const getPatientInfoSuccess = createAction(
  patientConstants.GET_PATIENT_INFO_SUCCESS,
  (payload) => ({ payload })
);

export const getPatientInfoFailure = createAction(patientConstants.GET_PATIENT_INFO_FAILURE);

export const setGeneralFormEdit = createAction(
  patientConstants.SET_GENERAL_FORM_EDIT,
  (payload) => ({ payload })
);

export const setPayerFormEdit1 = createAction(
  patientConstants.SET_PAYER_FORM_EDIT_1,
  (payload) => ({ payload })
);

export const setPayerFormEdit2 = createAction(
  patientConstants.SET_PAYER_FORM_EDIT_2,
  (payload) => ({ payload })
);

export const setPayerFormEdit3 = createAction(
  patientConstants.SET_PAYER_FORM_EDIT_3,
  (payload) => ({ payload })
);

export const setFamilyFormEdit1 = createAction(
  patientConstants.SET_FAMILY_FORM_EDIT_1,
  (payload) => ({ payload })
);

export const setFamilyFormEdit2 = createAction(
  patientConstants.SET_FAMILY_FORM_EDIT_2,
  (payload) => ({ payload })
);

export const setFamilyFormEdit3 = createAction(
  patientConstants.SET_FAMILY_FORM_EDIT_3,
  (payload) => ({ payload })
);

export const setFacilityFormEdit = createAction(
  patientConstants.SET_FACILITY_FORM_EDIT,
  (payload) => ({ payload })
);

export const setCareteamFormEdit1 = createAction(
  patientConstants.SET_CARETEAM_FORM_EDIT_1,
  (payload) => ({ payload })
);

export const setCareteamFormEdit2 = createAction(
  patientConstants.SET_CARETEAM_FORM_EDIT_2,
  (payload) => ({ payload })
);

export const setCareteamFormEdit3 = createAction(
  patientConstants.SET_CARETEAM_FORM_EDIT_3,
  (payload) => ({ payload })
);

export const setPatientStatus = createAction(
  patientConstants.SET_PATIENT_STATUS,
  (payload) => ({ payload })
);

export const setPatientStatusSuccess = createAction(
  patientConstants.SET_PATIENT_STATUS_SUCCESS,
  (payload) => ({ payload })
);

export const setPatientStatusFailure = createAction(patientConstants.SET_PATIENT_STATUS_FAILURE);

export const getDiagnosesPayload = createAction(
  patientConstants.GET_DIAGNOSES_PAYLOAD,
  (payload) => ({ payload })
);

export const getDiagnosesPayloadSuccess = createAction(
  patientConstants.GET_DIAGNOSES_PAYLOAD_SUCCESS,
  (payload) => ({ payload })
);

export const getDiagnosesPayloadFailure = createAction(patientConstants.GET_DIAGNOSES_PAYLOAD_FAILURE);