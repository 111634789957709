import React, { useState, useContext, useEffect } from "react";
import styles from "./Homepage.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import ChrysanthemumBlack from "../../../Assets/Images/ChrysanthemumBlack.png";
import { PatientDataProvider } from "../../Store/PatientDataProvider";
import { AccountContext } from "../../Store/AuthenticationStore/Account";
import AddNewUserInfoForm from "../HomePage/AddNewUserInfoForm/AddNewUserInfoForm";
import { UserContext } from "../../Store/UserContext";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import CopyrightIcon from '@mui/icons-material/Copyright';
import logoutImg from '../../../../src/Assets/Images/Logout.png';

import {
  getUserProfile,
  getSelectedProjectIdAndLocationId,
  getSelectedPageName,
  getUserRole,
  getSelectedMlIdSelector,
  getShowQMDrawer,
  getShowMEDrawer,
  getShowCMDrawer,
  getIsCalOngoing,
  getTimerState,
} from "../../../Reducer/selectors/DashboardSelector";
import { Dashboard, Dns, Contacts, Hub, ExitToApp } from "@mui/icons-material";
import HelpCenter from '@mui/icons-material/HelpCenter';
import { getFacilityInfo, getIfEmailAlreadyExistsSuccess, setAbortTimerRedirectLink, setLocationName, setProjectName, setSelectedPageName, setShowTimerNotCompleteDialog } from "../../../Actions";
import { globalConstants } from "../../../Constants/GlobalConstants";
import { setPRCareTeamInfo, setPRFacilityInfo, setPRFamilyInfo, setPRGeneralInfo, setPRPayerInfo, setRegistrationStatus, setRegistrationStep, setShowEmailExistsLoader } from "../../../Actions/UtilActions";

// Drawer Styles
// The drawer used in this application is a persistent drawer
// the drawer is implemented using material ui design core

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // backgroundColor: "#F2F2F7",
    //height: `100%`,
    //overflowX: `hidden`,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
    justifyContent: `space-around`,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#4E253A",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#4E253A",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#4E253A",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: `red`,
  },
  colorScheme: {
    backgroundColor: "#322F4B",
  },
  listColor: {
    color: "#F7F9F9",
  },
  menu: {
    height: 420,
    width: 400,
  },
  notificationsMenu: {
    height: 500,
    width: 500,
  },
  tooltip: {
    marginTop: 0,
    font: "12px",
    background: "black",
    fontFamily: "GTWalsheimProRegular",
  },
}));

export default function Homepage() {
  //let curUserEmail = useParams();
  // from auth context
  let { logout } = useContext(AccountContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const projectAndLocation = useSelector(getSelectedProjectIdAndLocationId);
  const profile = useSelector(getUserProfile);

  const selectedMLId = useSelector(getSelectedMlIdSelector);
  const isQMDrawerOpen = useSelector(getShowQMDrawer);
  const isMEDrawerOpen = useSelector(getShowMEDrawer);
  const isCMDrawerOpen = useSelector(getShowCMDrawer);
  const isCalOngoing = useSelector(getIsCalOngoing);
  const isAnyDrawerOpen = isQMDrawerOpen || isMEDrawerOpen || isCMDrawerOpen || isCalOngoing;

  // State to control drawer operations - open and close
  const [drawerControl, setDrawerControl] = useState(false);

  // Function to control drawer operations

  const handleDrawerOpen = () => {
    setDrawerControl(true);
  };

  const handleDrawerClose = () => {
    setDrawerControl(false);
  };

  // Logout Pop UP States
  const [isLogout, setIsLogout] = useState(false);
  const openLogoutMenu = () => {
    setIsLogout(true);
  };
  const closeLogoutMenu = () => {
    setIsLogout(false);
  };

  //Adding New User Info (only for admins!)
  const [openNewUserInfo, setOpenNewUserInfo] = React.useState(false);

  const openNewUserModal = () => {
    setOpenNewUserInfo(true);
  };

  const handleCloseNewUserInfo = () => {
    setOpenNewUserInfo(false);
  };

  const clearAllRegistrationInfo = () => {

    const payload = {
      locationId: projectAndLocation.selectedLocationId,
    };

    dispatch(getFacilityInfo(payload));

    const newRegStatus = {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      review: false,
    };
    dispatch(setRegistrationStatus(newRegStatus));
    dispatch(setRegistrationStep("1"));
    dispatch(setPRGeneralInfo({
      ehrId: '',
      prefix: '',
      firstName: '',
      middleName: '',
      suffix: '',
      lastName: '',
      prevName: '',
      dob: new Date(),
      ssn: '',
      bioSex: '',
      genderIdentity: '',
      sexOrientation: '',
      phNum: '',
      email: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    }));
    dispatch(setPRPayerInfo({
      payerType: '',
      payerTypeOther: '',
      priPayerName: '',
      medicareBeneId: '',
      payerRel: '',
      medicaidBeneId: '',
      managedCareId: '',
      priPayerPh: '',
      edu: '',
      occupation: '',
      empStatus: '',
      religion: '',
      marStatus: '',
      ethnicity: '',
      smokeStatus: '',
      race: '',
      primLang: '',
      primCond: '',
      primProg: '',
      interpNeed: '',
      secCond: '',
      secProg: '',
      height: '',
      terCond: '',
      weight: '',
      otherCom: '',
    }));
    dispatch(setPRFamilyInfo({
      prefixC1: '',
      firstNameC1: '',
      suffixC1: '',
      lastNameC1: '',
      relC1: '',
      bioSexC1: '',
      emailC1: '',
      phNumC1: '',
      conTypeC1: [],
      comModeC1: [],

      prefixC2: '',
      firstNameC2: '',
      suffixC2: '',
      lastNameC2: '',
      relC2: '',
      bioSexC2: '',
      emailC2: '',
      phNumC2: '',
      conTypeC2: [],
      comModeC2: [],

      prefixC3: '',
      firstNameC3: '',
      suffixC3: '',
      lastNameC3: '',
      relC3: '',
      bioSexC3: '',
      emailC3: '',
      phNumC3: '',
      conTypeC3: [],
      comModeC3: [],

      consentSel: '',
    }));
    dispatch(setPRCareTeamInfo({
      prefixC1: '',
      firstNameC1: '',
      suffixC1: '',
      lastNameC1: '',
      specC1: '',
      profC1: '',
      npiC1: '',
      emailC1: '',
      phNumC1: '',
      comModeC1: [],

      prefixC2: '',
      firstNameC2: '',
      suffixC2: '',
      lastNameC2: '',
      specC2: '',
      profC2: '',
      npiC2: '',
      emailC2: '',
      phNumC2: '',
      comModeC2: [],

      prefixC3: '',
      firstNameC3: '',
      suffixC3: '',
      lastNameC3: '',
      specC3: '',
      profC3: '',
      npiC3: '',
      emailC3: '',
      phNumC3: '',
      comModeC3: [],
    }));
    dispatch(setPRFacilityInfo({
      facilityName: '',
      facilityNum: '',
      facilityType: '',
      bedNum: '',
      actCode: '',
      unit: '',
      floor: '',
      roomNum: '',
      admDate: new Date(),
    }));
  };

  const userContext = useContext(UserContext);
  const isPrjLocLoaded = projectAndLocation.selectedProjectId && projectAndLocation.selectedLocationId;

  // const [isProjectsSelected, setIsProjectSelected] = React.useState(true);
  // const [isPatientsSelected, setIsPatientsSelected] = React.useState(true);
  // const [isRegisterSelected, setIsRegisterSelected] = React.useState(false);
  // const [isLogsSelected, setIsLogsSelected] = React.useState(false);
  const selectedPageName = useSelector(getSelectedPageName);
  const dispatch = useDispatch();
  const role = useSelector(getUserRole);

  const pList = [
    {
      guid: "c948a5b5-d71e-4e84-9a3b-a4d4bd0f1cfb",
      subjectId: "731",
      deviceId: "None",
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      status: "Registered",
      registeredBy: "Test Tester",
      adherence: "0",
      adherence16: "0",
      elapsedHours: "1203",
      diagnoses: [""],
      email: "",
      dateOfFirstMeasurement: "None",
    },
  ];

  const timerState = useSelector(getTimerState);
  //   console.log("selected pagename: ", selectedPageName);

  useEffect(() => { }, [role]);

  return (
    <div className={classes.root}>
      {role != globalConstants.USER_ROLE_PATIENT && (
        <Drawer
          variant="permanent"
          /* classes={{ colorScheme: classes.colorScheme }} */
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerControl,
            [classes.drawerClose]: !drawerControl,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerControl,
              [classes.drawerClose]: !drawerControl,
            }),
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            //edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerControl,
            })}
          >
            <Avatar alt="aidar-logo" src={ChrysanthemumBlack} />
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            className={clsx(classes.menuButton, {
              [classes.hide]: !drawerControl,
            })}
          >
            <Avatar alt="A" src={ChrysanthemumBlack} />
            <div style={{ marginLeft: 10 }} className={styles.aidarName}>
              {/* Aidar Health */}
            </div>
            <ChevronLeftIcon style={{ fill: "#F7F9F9", marginLeft: 10 }} />
          </IconButton>

          <List className={styles.listAlignmentStyles} style={{ marginLeft: 7 }}>
            <Tooltip title="Projects" classes={{ tooltip: classes.tooltip }} slots="arrow">
              {/* <NavLink to={`/projectlocations`} className={styles.navlinkStyle}> */}
              <ListItem
                button
                onClick={() => {
                  if (timerState != 0) {
                    dispatch(setShowTimerNotCompleteDialog(true));
                    dispatch(setAbortTimerRedirectLink("/projectlocations"));
                    dispatch(setLocationName(""));
                    dispatch(setProjectName(""));
                  } else {
                    dispatch(setLocationName(""));
                    dispatch(setProjectName(""));
                    navigate("/projectlocations");
                  }
                }}
              >
                <ListItemIcon>
                  <Dashboard className={selectedPageName === "projects" ? styles.iconStylesSelected : styles.iconStyles} size={25} />
                </ListItemIcon>
                <ListItemText className={styles.listColor}>
                  <div className={styles.listColor}>Projects</div>
                </ListItemText>
              </ListItem>
              {/* </NavLink> */}
            </Tooltip>
          </List>

          {(role === globalConstants.USER_ROLE_AIDAR_ADMIN || role === globalConstants.USER_ROLE_BILLING_PHYSICIAN || role === globalConstants.USER_ROLE_CARE_MANAGER) && (
            <>
              {isPrjLocLoaded && (
                <List className={styles.listAlignmentStyles} style={{ marginLeft: 7 }}>
                  <Tooltip title="Patients List" classes={{ tooltip: classes.tooltip }}>
                    {/* <NavLink to={`/subjects/${userContext.curPlSelections.projectId}/${userContext.curPlSelections.locationId}`} className={styles.navlinkStyle} style={isPrjLocLoaded ? {} : {pointerEvents: "none"}}> */}
                    <ListItem
                      button
                      disabled={!isPrjLocLoaded}
                      onClick={() => {
                        if (timerState != 0) {
                          dispatch(setShowTimerNotCompleteDialog(true));
                          dispatch(
                            setAbortTimerRedirectLink("/subjects/" + projectAndLocation.selectedProjectId + "/" + projectAndLocation.selectedLocationId)
                          );
                        } else {
                          navigate("/subjects/" + projectAndLocation.selectedProjectId + "/" + projectAndLocation.selectedLocationId, {
                            state: {
                              userEmail: profile.email,
                              userFirstName: profile.firstName,
                              userLastName: profile.lastName,
                            },
                          });
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Dns className={selectedPageName === "patientslist" && isPrjLocLoaded ? styles.iconStylesSelected : styles.iconStyles} size={25} />
                      </ListItemIcon>
                      <ListItemText className={styles.listColor}>
                        <div className={styles.listColor}>Patients List</div>
                      </ListItemText>
                    </ListItem>
                    {/* </NavLink> */}
                  </Tooltip>
                </List>
              )}

              {isPrjLocLoaded && (
                <List className={styles.listAlignmentStyles} style={{ marginLeft: 7 }}>
                  <Tooltip title="Register Patient" classes={{ tooltip: classes.tooltip }}>
                    {/* <NavLink
                  to={`/register`}
                  className={styles.navlinkStyle}
                  style={isPrjLocLoaded ? {} : { pointerEvents: "none" }}
                > */}
                    <ListItem
                      button
                      disabled={!isPrjLocLoaded}
                      onClick={() => {
                        dispatch(setShowEmailExistsLoader(false));
                        dispatch(getIfEmailAlreadyExistsSuccess(false));
                        if (timerState != 0) {
                          dispatch(setShowTimerNotCompleteDialog(true));
                          clearAllRegistrationInfo();
                          dispatch(setSelectedPageName('register'));
                          dispatch(setAbortTimerRedirectLink("/register"));
                        } else {
                          clearAllRegistrationInfo();
                          dispatch(setSelectedPageName('register'));
                          navigate("/register");
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Contacts className={selectedPageName === "register" ? styles.iconStylesSelected : styles.iconStyles} size={25} />
                      </ListItemIcon>
                      <ListItemText className={styles.listColor}>
                        <div className={styles.listColor}>Register Patients</div>
                      </ListItemText>
                    </ListItem>
                    {/* </NavLink> */}
                  </Tooltip>
                </List>
              )}

              {isPrjLocLoaded && (role !== globalConstants.USER_ROLE_CARE_MANAGER) && (role !== globalConstants.USER_ROLE_BILLING_PHYSICIAN) && (
                <List className={styles.listAlignmentStyles} style={{ marginLeft: 7 }}>
                  <Tooltip title="Device Assignment" classes={{ tooltip: classes.tooltip }}>
                    {/* <NavLink
                  to={`/deviceAssignments`}
                  className={styles.navlinkStyle}
                  style={isPrjLocLoaded ? {} : { pointerEvents: "none" }}
                > */}
                    <ListItem
                      button
                      disabled={!isPrjLocLoaded}
                      onClick={() => {
                        if (timerState != 0) {
                          dispatch(setShowTimerNotCompleteDialog(true));
                          dispatch(setAbortTimerRedirectLink("/deviceAssignments"));
                        } else {
                          navigate("/deviceAssignments");
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Hub className={selectedPageName === "logs" ? styles.iconStylesSelected : styles.iconStyles} size={25} />
                      </ListItemIcon>
                      <ListItemText className={styles.listColor}>
                        <div className={styles.listColor}>Device Assignment</div>
                      </ListItemText>
                    </ListItem>
                    {/* </NavLink> */}
                  </Tooltip>
                </List>
              )}
            </>
          )}

          {(timerState === 0) && (<>
            <List style={{ marginLeft: 6, position: "absolute", bottom: 40 }} className={styles.something}>
              <Tooltip title="Support"
                classes={{ tooltip: classes.tooltip }}
                PopperProps={{
                  popperOptions: {
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "-10px, -50px",
                      },
                    },
                  },
                }}
              >
                <NavLink to={`/help`} className={styles.navlinkStyle} >
                  <ListItem button>
                    <ListItemIcon>
                      <HelpCenter className={selectedPageName === "help" ? styles.iconStylesSelected : styles.iconStyles} size={25} />
                    </ListItemIcon>
                    <ListItemText className={styles.listColor}>
                      <div className={styles.listColor}>Support</div>
                    </ListItemText>
                  </ListItem>
                </NavLink>
              </Tooltip>
            </List>

            <List style={{ marginLeft: 1, position: "absolute", bottom: 0 }} className={styles.something}>
              <Tooltip
                title="Logout"
                classes={{ tooltip: classes.tooltip }}
                PopperProps={{
                  popperOptions: {
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "10px, -50px",
                      },
                    },
                  },
                }}
              >
                <ListItem button style={{ bottom: 0 }} onClick={openLogoutMenu}>
                  <ListItemIcon>
                    <ExitToApp style={{ marginLeft: `5px`, marginRight: `5px` }} className={styles.iconStyles} size={25} />
                  </ListItemIcon>
                  <ListItemText className={styles.listColor}>
                    <div className={styles.listColor}>Logout</div>
                  </ListItemText>
                </ListItem>
              </Tooltip>
            </List>
          </>)}
        </Drawer>
      )}

      <PatientDataProvider>
        <main className={styles.content}>
          <Outlet />
        </main>
      </PatientDataProvider>

      {/* Logout menu */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={isLogout}
        onClose={closeLogoutMenu}
      >
        <div className={styles.paper}>
          <div className={styles.menuTitle}>Logout</div>

          <div className={styles.patientListDiv}>
            <Grid container>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.userQuestionMessage}>Are you sure you would like to log out?</div>

                <div className={styles.mouthLabImgDiv}>
                  <img src={logoutImg} loading="lazy" className={styles.mlImg} />
                </div>

                <div className={styles.buttonContainer}>
                  <button type="button" onClick={closeLogoutMenu} className={styles.cancelButton}>
                    Cancel
                  </button>
                  <button type="submit" onClick={logout} className={styles.submitButton}>
                    Confirm
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
      {/* Logout Menu */}

    </div>
  );
}
