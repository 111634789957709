import { dashboardConstants } from "../Constants/DashboardConstants";

const initialState = {
  userProfile: undefined,
  isLoggedIn: false,
  userEmail: '',
  projects: [],
  locations: [],
  selectedProjectId: '',
  selectedLocationId: '',
  mlvitalsWrapperId: 0,
  rulesWrapperId: 0,
  trendsWrapperId: 0,
  selectedSubjectId: '',
  selectedPageName: '',
  notesPanel: false,
  notesDrawer: false,
  qmDrawer: false,
  meDrawer: false,
  cmDrawer: false,
  selectedMlId: "",
  selectedPCCPatient: {},
  isCalibrationOngoing: false,
  timerState: 0,
  isTimerRunning: false,
  timerValue: 0,
  timerId: '',
  timerDrawer: false,
  showTimerNotCompleteDialog: false,
  shouldAbortTimer: false,
  abortTimerRedirectLink: "",
  projectName: "",
  locationName: "",
  curSubjectName: "",
  curSubjectStatus: "",
};

export function dashboardReducer(state = initialState, action) {

  switch (action.type) {
    case dashboardConstants.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        userProfile: undefined,
      };
    case dashboardConstants.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        selectedProjectId: '',
        selectedLocationId: ''
      };
    case dashboardConstants.SET_PROJECTID_AND_LOCATIONID:
      return {
        ...state,
        selectedProjectId: action.payload?.projectId,
        selectedLocationId: action.payload?.locationId
      };
    case dashboardConstants.RESET_PROJECTID_LOCATIONID:
      return {
        ...state,
        selectedProjectId: '',
        selectedLocationId: ''
      };
    case dashboardConstants.SET_MLVITALS_WRAPPER_ID:
      return {
        ...state,
        mlvitalsWrapperId: action.payload
      };
    case dashboardConstants.SET_RULES_WRAPPER_ID:
      return {
        ...state,
        rulesWrapperId: action.payload
      };
    case dashboardConstants.SET_TRENDS_WRAPPER_ID:
      return {
        ...state,
        trendsWrapperId: action.payload
      };
    case dashboardConstants.SET_SELECTED_SUBJECTID:
      return {
        ...state,
        selectedSubjectId: action.payload
      };
    case dashboardConstants.SET_SELECTED_PAGE_NAME:
      return {
        ...state,
        selectedPageName: action.payload
      };
    case dashboardConstants.ATTEMPT_LOGOUT:
      return { ...initialState };

    case dashboardConstants.SET_SHOW_NOTES_PANEL:
      return {
        ...state,
        notesPanel: action.payload
      };

    case dashboardConstants.SET_SHOW_NOTES_DRAWER:
      return {
        ...state,
        notesDrawer: action.payload
      };

    case dashboardConstants.SET_SHOW_TIMER_DRAWER:
      return {
        ...state,
        timerDrawer: action.payload
      };

    case dashboardConstants.SET_SHOW_QM_DRAWER:
      return {
        ...state,
        qmDrawer: action.payload
      };

    case dashboardConstants.SET_SHOW_ME_DRAWER:
      return {
        ...state,
        meDrawer: action.payload
      };

    case dashboardConstants.SET_SHOW_CM_DRAWER:
      return {
        ...state,
        cmDrawer: action.payload
      };

    case dashboardConstants.SET_SELECTED_ML_ID:
      return {
        ...state,
        selectedMlId: action.payload
      };

    case dashboardConstants.SET_SELECTED_PCC_PATIENT:
      return {
        ...state,
        selectedPCCPatient: action.payload
      };

    case dashboardConstants.SET_IS_CAL_ONGOING:
      return {
        ...state,
        isCalibrationOngoing: action.payload
      };

    case dashboardConstants.SET_TIMER_STATE:
      return {
        ...state,
        timerState: action.payload
      };

    case dashboardConstants.SET_IS_TIMER_RUNNING:
      return {
        ...state,
        isTimerRunning: action.payload
      };

    case dashboardConstants.SET_TIMER_VALUE:
      return {
        ...state,
        timerValue: action.payload
      };

    case dashboardConstants.SET_TIMER_ID:
      return {
        ...state,
        timerId: action.payload
      };

    case dashboardConstants.SET_SHOW_TIMER_NOT_COMPLETE_DIALOG:
      return {
        ...state,
        showTimerNotCompleteDialog: action.payload
      };

    case dashboardConstants.SET_SHOULD_ABORT_TIMER:
      return {
        ...state,
        shouldAbortTimer: action.payload
      };

    case dashboardConstants.SET_ABORT_TIMER_REDIRECT_LINK:
      return {
        ...state,
        abortTimerRedirectLink: action.payload
      };

    case dashboardConstants.SET_PROJECT_NAME:
      return {
        ...state,
        projectName: action.payload
      };

    case dashboardConstants.SET_LOCATION_NAME:
      return {
        ...state,
        locationName: action.payload
      };

    case dashboardConstants.SET_CURR_SUBJECT_NAME:
      return {
        ...state,
        curSubjectName: action.payload
      };

    case dashboardConstants.SET_CURR_SUBJECT_STATUS:
      return {
        ...state,
        curSubjectStatus: action.payload
      };

    default:
      return state;
  };

}