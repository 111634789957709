import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import styles from "./TimerDrawer.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@mui/material/IconButton";
import { setShowNotesPanel, setOpenAddNotesPanel, getPatientNotes, setSelectedTimestamp, setSelectedSubjectId, setShowTimerDrawer, getTimerInfo, addTimerWithNotes } from "../../../../Actions";
import { getCurSubjectName, getShowNotesPanel, getShowTimerDrawer, getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getSelectedSubjectId } from "../../../../Reducer/selectors/DashboardSelector";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import Input from "@material-ui/core/Input";
import { setShowLoader } from "../../../../Actions/UtilActions";
import { getOpenAddNotesPanelSelector } from "../../../../Reducer/selectors/NotesSelector";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { PatientContext } from "../../../Store/PatientDataProvider";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import AccessAlarmsSharpIcon from "@mui/icons-material/AccessAlarmsSharp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
    getLatestMonthTimerInfoSelector,
    getTimerCreatedUserNPI,
    getTimerCreatedUserName,
    getTimerInfoSelector,
} from "../../../../Reducer/selectors/TimerSelector";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock, renderDigitalClockTimeView } from "@mui/x-date-pickers/timeViewRenderers";
import { getCurrentPatientGUID, getCurrentPatientSubjectID, getPatientTimerDrawerLoader } from "../../../../Reducer/selectors/UtilSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getDisplayLabelWithEhrId, getDisplayLabelWithoutEhrId } from "../../../../Utils/GlobalFunctions";
import { getPatientInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import { globalConstants } from "../../../../Constants/GlobalConstants";


export default function TimerDrawer(props) {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const [isNewNotes, setIsNewNotes] = useState(false);
    const [newNotes, setNewNotes] = useState("");
    const [tagNames, setTagNames] = useState("");
    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;
    const [expandedRecentNoteId, setExpandedRecentNoteId] = useState("");
    const patientInfo = useContext(PatientContext);
    let refreshData = patientInfo.isRefresh;
    const currPatientGUID = useSelector(getCurrentPatientGUID);
    const currPatientSubjectID = useSelector(getCurrentPatientSubjectID);
    const dispatch = useDispatch();
    const patientTimerDrawerLoader = useSelector(getPatientTimerDrawerLoader);
    const timerCreatedUserNPI = useSelector(getTimerCreatedUserNPI);

    // Notes drawer and panel actions
    let drawerOneAction = useSelector(getShowNotesPanel);
    let drawerTwoAction = useSelector(getShowTimerDrawer);

    let drawerState = {
        drawerOne: drawerOneAction,
        drawerTwo: drawerTwoAction,
    };

    const [errorMessage, setErrorMessage] = useState(''); // Initialize with an empty string
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [timeSpentError, setTimeSpentError] = useState('');

    const handleInvalidFieldsCheck = (newNote, newValue) => {
        if ((newValue.get('second') <= 0 && newValue.get('minute') <= 0) || newNote.length === 0) { // Adjust the condition as needed
            setTimeSpentError('Please enter the required fields.');
            setIsSubmitDisabled(true);
        } else {
            setTimeSpentError('');
            setIsSubmitDisabled(false);
        }
    };

    const handleDateTimeChange = (newValue) => {
        // Check if the selected date and time are in the future
        const currentDate = new Date();
        if (newValue > currentDate) {
            setErrorMessage('Please do not select a future date or time.');
            setIsSubmitDisabled(true);
        } else {
            setErrorMessage('');
            setIsSubmitDisabled(false);
        }
        setSelectedDateTime(newValue);
    };

    const handleSetNewNotes = (newNote) => {
        setNewNotes(newNote);
        handleInvalidFieldsCheck(newNote, timeSpent);
    }

    const openAddNotesPanel = useSelector(getOpenAddNotesPanelSelector);

    const patientDetailInfo = useSelector(getPatientInfoSelector);

    const getPatientLabel = (patientDetailInfo) => {
        if (patientDetailInfo.contactInfo.firstName != "") {
            // if (patientDetailInfo.ehrId != null && patientDetailInfo.ehrId != "") {
            //   return getDisplayLabelWithEhrId(patientDetailInfo.firstName, patientDetailInfo.lastName, patientDetailInfo.ehrId);
            // }
            let fullName = getDisplayLabelWithoutEhrId(patientDetailInfo.contactInfo.firstName, patientDetailInfo.contactInfo.lastName);
            if (fullName.length > 15) {
                // Trim the combined string to 15 characters and add ",.."
                //dispatch(setCurrSubjectName(fullName.substring(0, 15) + "...")); 
                return fullName.substring(0, 15) + "...";
            } else {
                // Use the full combined name
                //dispatch(setCurrSubjectName(fullName)); 
                return fullName;
            }
        }
        const subjectlabel = "ADR - " + patientDetailInfo.subjectId;
        return subjectlabel;
    };
    const curSubjectName = getPatientLabel(patientDetailInfo);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonth = monthNames[new Date().getMonth()];

    const latestMonthTimerInfo = useSelector(getLatestMonthTimerInfoSelector);
    //console.log("latest month: ", latestMonthTimerInfo);
    const previousMonthsTimerInfo = useSelector(getTimerInfoSelector);

    const [latestMonthTimerData, setLatestMonthTimerData] = useState(latestMonthTimerInfo);
    const [previousMonthTimerData, setPreviousMonthTimerData] = useState(previousMonthsTimerInfo);

    let cummulativeMins = Math.floor(latestMonthTimerData.totalDuration / 60);
    let cummulativeSecs = latestMonthTimerData.totalDuration % 60;

    const timerColors = ["#67E38A", "#EAA246", "#E55151"];
    const currTimerColor =
        latestMonthTimerData.totalDuration < 600 ? timerColors[2] : latestMonthTimerData.totalDuration < 1200 ? timerColors[1] : timerColors[0];

    const remTimeToNextMilestone =
        latestMonthTimerInfo.totalDuration < 1200
            ? Math.ceil((1200 - latestMonthTimerInfo.totalDuration) / 60)
            : latestMonthTimerInfo.totalDuration < 2400
                ? Math.ceil((2400 - latestMonthTimerInfo.totalDuration) / 60)
                : 0;

    const [milestoneWarningMsg, setMilestoneWarningMsg] = useState(remTimeToNextMilestone + " minutes to reach your milestone");

    const [selectedDateTime, setSelectedDateTime] = useState(dayjs(new Date().toISOString()));
    const [timeSpent, setTimeSpent] = useState(dayjs(new Date().toISOString()).set("minute", 0).set("second", 0));

    function formatTime(totalSeconds) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    function formatTime1(value) {
        return value < 10 ? `0${value}` : `${value}`;
    }

    const useStyles = makeStyles((theme) => ({
        patientDetailsDiv: {
            marginRight: (drawerState) => (drawerState.drawerTwo ? "350px" : drawerState.drawerOne ? "50px" : "0px"),
            overflowX: "hidden",
            transition: (drawerState) =>
                !drawerState.drawerOne
                    ? theme.transitions.create("margin", {
                        easing: theme.transitions.easing.sharp,
                        duration: "500ms",
                    })
                    : theme.transitions.create("margin", {
                        easing: theme.transitions.easing.easeOut,
                        duration: "500ms",
                    }),
        },

        primaryDrawer: {
            width: "50px",
            zIndex: 5,
            flexShrink: 0,
            "& .MuiDrawer-root": (drawerState) =>
                drawerState.drawerTwo
                    ? {
                        position: "absolute",
                        marginRight: "300px",
                    }
                    : {
                        position: "relative",
                        marginRight: "0px",
                    },
            "& .MuiDrawer-paper": {
                backgroundColor: "#FCF9FA",
            },
            transition: (drawerState) =>
                !drawerState.drawerTwo
                    ? theme.transitions.create("margin", {
                        easing: theme.transitions.easing.sharp,
                        duration: "500ms",
                    })
                    : theme.transitions.create("margin", {
                        easing: theme.transitions.easing.easeOut,
                        duration: "500ms",
                    }),
        },

        primaryDrawerPaper: {
            width: "50px",
            boxSizing: "border-box",
            zIndex: 5,
            position: "absolute",
            marginRight: (drawerState) => (drawerState.drawerTwo ? "300px" : "0px"),
            transition: (drawerState) =>
                !drawerState.drawerTwo
                    ? theme.transitions.create("margin", {
                        easing: theme.transitions.easing.sharp,
                        duration: "500ms",
                    })
                    : theme.transitions.create("margin", {
                        easing: theme.transitions.easing.easeOut,
                        duration: "500ms",
                    }),
        },

        secondaryDrawer: {
            maximumWidth: "300px",
            zIndex: 3,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                backgroundColor: "#F3E8EE",
            },
            transition: (drawerState) =>
                !drawerState.drawerTwo
                    ? theme.transitions.create("margin", {
                        easing: theme.transitions.easing.sharp,
                        duration: "500ms",
                    })
                    : theme.transitions.create("margin", {
                        easing: theme.transitions.easing.easeOut,
                        duration: "500ms",
                    }),
        },

        secondaryDrawerPaper: {
            width: "300px",
            boxSizing: "border-box",
            zIndex: 3,
            transition: (drawerState) =>
                !drawerState.drawerTwo
                    ? theme.transitions.create("margin", {
                        easing: theme.transitions.easing.sharp,
                        duration: "500ms",
                    })
                    : theme.transitions.create("margin", {
                        easing: theme.transitions.easing.easeOut,
                        duration: "500ms",
                    }),
        },
        tooltip: {
            //marginTop: 5,
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const classes = useStyles(drawerState);

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    }));

    const classesTooltip = useStyles();

    const handleNotesDrawerOpen = (event) => {
        event.preventDefault();
        dispatch(setShowNotesPanel(true));
        dispatch(setShowTimerDrawer(!drawerState.drawerTwo));

        if (!drawerState.drawerTwo) {
            dispatch(setOpenAddNotesPanel(false));
            const payload = {
                subjectId: selectedSubjectId.selectedSubjectId,
                recordId: "",
            };
            dispatch(getPatientNotes(payload));
        }
    };

    const handleAddNewNotes = (event) => {
        event.preventDefault();
        dispatch(setOpenAddNotesPanel(true));
        setNewNotes("");
        setTagNames("");
        dispatch(setSelectedTimestamp(""));
        handleInvalidFieldsCheck(newNotes, timeSpent);
    };

    const handleCancelAddNewNotes = (event) => {
        event.preventDefault();
        dispatch(setOpenAddNotesPanel(false));
        setNewNotes("");
        setTagNames("");
        resetNotesState();
        setTimeSpentError('');
        setErrorMessage('');
    };

    const handleAddTag = (tag) => {
        /* if (tagNames.includes(tag)) {
            const filteredTags = tagNames.filter((item) => item !== tag);
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, tag]);
        } */
        setTagNames(tag);
    };

    const submitTimerWithNotes = () => {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const payload = {
            subjectId: currPatientSubjectID,
            subjectGuid: currPatientGUID,
            userId: userProfile.id,
            start_time: selectedDateTime.toDate(),
            end_time: selectedDateTime.add(timeSpent.get("minute") * 60 + timeSpent.get("second"), "second").toDate(),
            duration: timeSpent.get("minute") * 60 + timeSpent.get("second"),
            status: "complete",
            note: newNotes,
            tags: tagNames,
            createdBy: userName,
            createdDateTime: new Date(),
            timezoneStr: timeZone,
        };
        //console.log("TIMER W NOTES PAYLOAD: ", payload);
        dispatch(setShowLoader(true));
        dispatch(addTimerWithNotes(payload));
        dispatch(setOpenAddNotesPanel(false));
        setNewNotes("");
        setTagNames("");
        resetNotesState();
        const timerPayload = {
            userId: userProfile.id,
            subjectGuid: currPatientGUID,
        };
        dispatch(getTimerInfo(timerPayload));
    };

    const handleExpandRecentNotes = (note) => {
        if (expandedRecentNoteId === note.id) {
            setExpandedRecentNoteId("");
        } else {
            setExpandedRecentNoteId(note.id);
        }
    };

    const resetNotesState = () => {
        setSelectedDateTime(dayjs(new Date().toISOString()));
        setTimeSpent(dayjs(new Date().toISOString()).set("minute", 0).set("second", 0));
        setNewNotes("");
        setTagNames("");
    };

    useEffect(() => {
        setMilestoneWarningMsg(remTimeToNextMilestone > 0 ? remTimeToNextMilestone + " minutes to reach next milestone" : "Your milestones have been reached");
    }, [remTimeToNextMilestone]);

    useEffect(() => {
        setIsNewNotes(openAddNotesPanel);
    }, [openAddNotesPanel]);

    useEffect(() => {
        dispatch(getTimerInfo({ userId: userProfile.id, subjectGuid: currPatientGUID }));
    }, [currPatientSubjectID, userProfile.id, refreshData]);

    useEffect(() => {
        setLatestMonthTimerData(latestMonthTimerInfo);
        setPreviousMonthTimerData(previousMonthsTimerInfo);
        cummulativeMins = Math.floor(latestMonthTimerData.totalDuration / 60);
        cummulativeSecs = latestMonthTimerData.totalDuration % 60;
    }, [previousMonthsTimerInfo, latestMonthTimerInfo]);

    useEffect(() => {
        dispatch(getTimerInfo({ userId: userProfile.id, subjectGuid: currPatientGUID }));
    }, [currPatientGUID]);

    useEffect(() => {
        resetNotesState();
        GetPatientInfo(currPatientGUID).then((res) => {
            dispatch(setSelectedSubjectId(res.subjectId));
            const timerPayload = {
                userId: userProfile.id,
                subjectGuid: currPatientGUID,
            };
            dispatch(getTimerInfo(timerPayload));
        });
    }, [currPatientGUID, userProfile.id, refreshData]);

    function ColorfulTagsGen(props) {
        if (!props.tags || props.tags.length === 0) {
            return <></>;
        } else {
            return (
                <>
                    {/* props.tags.map((tag) => */
                        props.tags === "Adherence" ? (
                            <div className={styles.selectedTagAdherence} key={props.tags}>{props.tags}</div>
                        ) : props.tags === "Medication" ? (
                            <div className={styles.selectedTagMedication} key={props.tags}>{props.tags}</div>
                        ) : props.tags === "Vitals" ? (
                            <div className={styles.selectedTagVitals} key={props.tags}>{props.tags}</div>
                        ) : props.tags === "Device" ? (
                            <div className={styles.selectedTagDevice} key={props.tags}>{props.tags}</div>
                        ) : props.tags === "Others" ? (
                            <div className={styles.selectedTagOthers} key={props.tags}>{props.tags}</div>
                        ) : (<div className={styles.selectedTagName} key={props.tags}>{props.tags}</div>)
                    /* )*/}
                </>
            );
        }
    };

    function createNameLabel(firstName, lastName, npiNumber) {
        let finalLastName = lastName;

        if (lastName.length > 10) {
            finalLastName = lastName.slice(0, 8) + "..";
        }

        return (Array.from(firstName)[0] + "." + finalLastName + " " + npiNumber);
    };

    const handleSetTimeSpent = (newValue) => {
        setTimeSpent(newValue);
        handleInvalidFieldsCheck(newNotes, newValue);
    }

    const timeZoneFromDayjs = dayjs.tz.guess();
    //console.log("tz:", dayjs.utc("2023-10-26 04:05:19").tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm'));

    const userRole = useSelector(getUserRole);

    return (
        <Drawer
            className={classes.secondaryDrawer}
            classes={{
                paper: classes.secondaryDrawerPaper,
            }}
            variant="persistent"
            anchor="right"
            open={drawerState.drawerTwo}
        >
            {patientTimerDrawerLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) :
                (<>
                    <DrawerHeader className={styles.drawerHeader}>
                        <div className={styles.headerLabel}>
                            Timer Logs <br /> {curSubjectName}
                        </div>
                        <IconButton size="large" className={styles.noteDrawerCloseIcon} onClick={(event) => handleNotesDrawerOpen(event)}>
                            <CancelIcon style={{ color: `#4E253A` }} />
                        </IconButton>
                    </DrawerHeader>

                    <div className={styles.dividerComponent} />
                    {cummulativeMins >= 0 && cummulativeSecs >= 0 && (
                        <>
                            <div className={styles.currMonthTotalTimeDiv}>
                                <div className={styles.displayCard}>
                                    <div className={styles.titleDiv}>
                                        <h4>Total Duration | {latestMonthTimerData.month}</h4>
                                    </div>
                                    <div className={styles.cummulativeTime} style={{ color: currTimerColor }}>
                                        <div className={styles.cummulativeMins} style={{ fontWeight: "bold", fontSize: "40px" }}>
                                            {formatTime1(cummulativeMins)}
                                        </div>
                                        m :&nbsp;<div className={styles.cummulativeMins} style={{ fontWeight: "bold", fontSize: "40px" }}>
                                            {formatTime1(cummulativeSecs)}
                                        </div>
                                        s
                                    </div>
                                    <div className={styles.milestoneWarning}>
                                        <AccessAlarmsSharpIcon sx={{ height: "18px", width: "18px", color: currTimerColor }}></AccessAlarmsSharpIcon>
                                        {milestoneWarningMsg}
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: "20px" }} className={styles.dividerComponent} />
                        </>
                    )}

                    <div className={styles.addNewTimerDiv}>
                        <IconButton size="medium" className={styles.addNotesIcon} onClick={(event) => handleAddNewNotes(event)} disabled={userRole === globalConstants.USER_ROLE_CARE_MANAGER}>
                            <AddCircleOutlineIcon style={{ color: "#4E253A" }}></AddCircleOutlineIcon>
                        </IconButton>
                        Log Time
                    </div>

                    {isNewNotes && (
                        <>
                            <div className={styles.notesInputDiv}>
                                <div className={styles.inputHeader}>
                                    <div className={styles.createdBy}>{userName}</div>
                                </div>
                                <div className={styles.dateTimePickerDiv}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Tooltip title="mm:ss" classes={{ tooltip: classesTooltip.tooltip }} placement="top">
                                            <div>
                                                <TimePicker
                                                    label="Time spent"
                                                    value={timeSpent}
                                                    onChange={(newValue) => handleSetTimeSpent(newValue)}
                                                    views={["minutes", "seconds"]}
                                                    format="mm:ss"
                                                    sx={{
                                                        "& .MuiInputBase-root": { fontSize: "11px", fontFamily: "GTWalsheimProRegular", padding: "5px" },
                                                        "& .MuiInputLabel-root": { fontSize: "12px", fontFamily: "GTWalsheimProRegular", fontWeight: "bold" },
                                                        "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
                                                        "& .MuiIconButton-root": { height: "20px", width: "20px", marginLeft: "-20px" },
                                                        "& .MuiOutlinedInput-input": { padding: "5px" },
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="mm-dd-yyyy" classes={{ tooltip: classesTooltip.tooltip }} placement="top">
                                            <div>
                                                <DatePicker
                                                    label="Date"
                                                    value={selectedDateTime}
                                                    onChange={(newValue) => { handleDateTimeChange(newValue); setSelectedDateTime(newValue); }}
                                                    maxDate={dayjs(new Date())}
                                                    sx={{
                                                        "& .MuiInputBase-root": { fontSize: "11px", fontFamily: "GTWalsheimProRegular", padding: "5px" },
                                                        "& .MuiInputLabel-root": { fontSize: "12px", fontFamily: "GTWalsheimProRegular", fontWeight: "bold" },
                                                        "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
                                                        "& .MuiIconButton-root": { height: "20px", width: "20px", marginLeft: "-10px" },
                                                        "& .MuiInputAdornment-root": { paddingRight: "8px" },
                                                        "& .MuiOutlinedInput-input": { padding: "5px" },
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="hh:mm" classes={{ tooltip: classesTooltip.tooltip }} placement="top">
                                            <div>
                                                <TimePicker
                                                    label="Time"
                                                    value={selectedDateTime}
                                                    onChange={(newValue) => { handleDateTimeChange(newValue); setSelectedDateTime(newValue); }}
                                                    ampm
                                                    minutesStep={1}
                                                    secondsStep={1}
                                                    // views={["hours", "minutes", "seconds"]}
                                                    sx={{
                                                        "& .MuiInputBase-root": { fontSize: "11px", fontFamily: "GTWalsheimProRegular", padding: "5px" },
                                                        "& .MuiInputLabel-root": { fontSize: "12px", fontFamily: "GTWalsheimProRegular", fontWeight: "bold" },
                                                        "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
                                                        "& .MuiIconButton-root": { height: "20px", width: "20px", marginLeft: "-10px" },
                                                        "& .MuiInputAdornment-root": { paddingRight: "8px" },
                                                        "& .MuiOutlinedInput-input": { padding: "5px" },
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </LocalizationProvider>
                                </div>
                                <div className={styles.tagsMenu}>
                                    <IconButton size="small" onClick={() => handleAddTag("Adherence")}>
                                        <div className={tagNames.includes("Adherence") ? styles.selectedTagAdherence : styles.tagNameAdherence}>Adherence</div>
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleAddTag("Medication")}>
                                        <div className={tagNames.includes("Medication") ? styles.selectedTagMedication : styles.tagNameMedication}>Medication</div>
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleAddTag("Vitals")}>
                                        <div className={tagNames.includes("Vitals") ? styles.selectedTagVitals : styles.tagNameVitals}>Vitals</div>
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleAddTag("Device")}>
                                        <div className={tagNames.includes("Device") ? styles.selectedTagDevice : styles.tagNameDevice}>Device</div>
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleAddTag("Others")}>
                                        <div className={tagNames.includes("Others") ? styles.selectedTagOthers : styles.tagNameOthers}>Others</div>
                                    </IconButton>
                                </div>
                                <div className={styles.notesTextArea}>
                                    <Input
                                        className={styles.notesEditor}
                                        type="text"
                                        placeholder="Start typing..."
                                        value={newNotes}
                                        multiline={true}
                                        onChange={(event) => handleSetNewNotes(event.target.value)}
                                        disableUnderline="true"
                                    />
                                </div>
                                <div className={styles.characterLimitDiv}>
                                    Character limit:{" "}
                                    {<div className={newNotes.length <= 0 || newNotes.length > 1000 ? styles.characterCountRed : styles.characterCount}>{newNotes.length}</div>}/1000
                                </div>
                                <div className={styles.errorMessage1Div}>
                                    <div className={styles.errorMessage1}>
                                        {errorMessage}
                                    </div>
                                </div>
                                <div className={styles.errorMessage2Div}>
                                    <div className={styles.errorMessage2}>
                                        {timeSpentError}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.addNotesButtons}>
                                <button className={styles.submitButton} onClick={(event) => handleCancelAddNewNotes(event)}>
                                    Cancel
                                </button>
                                <button
                                    className={styles.submitButton}
                                    onClick={() => {
                                        if (newNotes.length === 0 || newNotes.length > 1000 || isSubmitDisabled) {
                                            alert('Please do not select a future date or time.');
                                        } else {
                                            // Submit the timer with notes if conditions are met
                                            submitTimerWithNotes();
                                        }
                                    }}
                                    disabled={newNotes.length === 0 || newNotes.length > 1000 || isSubmitDisabled}
                                >
                                    Submit
                                </button>

                            </div>
                        </>
                    )}

                    <div style={{ marginTop: "20px" }} className={styles.dividerComponent} />

                    <div className={styles.recentNotesDiv}>
                        <IconButton
                            size="medium"
                            className={styles.addTimerIcon}
                        /*onClick={(event) => handleAddNewNotes(event)}*/
                        >
                            <CalendarTodayIcon style={{ height: "16px", width: "16px", color: "#4E253A" }}></CalendarTodayIcon>
                        </IconButton>
                        {currentMonth}
                    </div>
                    {latestMonthTimerData &&
                        latestMonthTimerData.monthWiseTimers &&
                        latestMonthTimerData.monthWiseTimers.map((notes) => {

                            return (
                                <>
                                    <div className={styles.notesInputDiv}>
                                        <div className={styles.inputHeader}>
                                            <div className={styles.createdBy}>
                                                {createNameLabel(firstName, lastName, timerCreatedUserNPI)}
                                            </div>
                                            <div className={styles.createdDateTime}>
                                                {
                                                    dayjs.utc(notes.endTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')
                                                }
                                            </div>
                                        </div>
                                        {expandedRecentNoteId === notes.id ? (
                                            <>
                                                <div className={styles.expandedNotesWrapper}>
                                                    <div className={styles.timerStatsDiv}>
                                                        <div className={styles.dummyDiv}>
                                                            <div className={styles.dummyColorDiv}>{/* {notes.noteText} */}</div>
                                                            <div className={styles.sessionTimeDiv}>
                                                                <div className={styles.timeTitle}>Session Time</div>
                                                                <div className={styles.timeDisplay}>{formatTime(notes.duration)}</div>
                                                                <div className={styles.timeLabels}>MIN SEC</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.monthlyTimeDiv}>
                                                            <div className={styles.timeTitle}>Monthly Time</div>
                                                            <div className={styles.timeDisplay}>
                                                                {`${formatTime1(Math.floor(latestMonthTimerData.totalDuration / 60))}:${formatTime1(latestMonthTimerData.totalDuration % 60)}`}
                                                            </div>
                                                            <div className={styles.timeLabels}>MIN SEC</div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.timerNotesDiv}>
                                                        <Input className={styles.notesTextExpanded} value={notes.noteText} multiline={true} readOnly={true} disableUnderline="true" />
                                                        <div className={styles.timerNotesFooter}>
                                                            <div className={styles.timerNotesTagsDiv}>
                                                                <ColorfulTagsGen tags={notes.tags} />
                                                            </div>
                                                            <div className={styles.collapseNotesButton}>
                                                                <IconButton size="small" onClick={() => handleExpandRecentNotes(notes)}>
                                                                    <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={styles.collapsedNotesWrapper}>
                                                    <div className={styles.dummyColorDiv}>{/* {notes.noteText} */}</div>
                                                    <div className={styles.notesWrapper}>
                                                        <Input className={styles.notesText} value={notes.noteText} multiline={true} readOnly={true} disableUnderline="true" />
                                                    </div>
                                                    <div className={styles.expanderDiv}>
                                                        <div className={styles.sessionTimeDiv}>
                                                            <div className={styles.timeTitle}>Session Time</div>
                                                            <div className={styles.timeDisplay}>{formatTime(notes.duration)}</div>
                                                            <div className={styles.timeLabels}>MIN SEC</div>
                                                        </div>
                                                        <div className={styles.expandButtonWrapper}>
                                                            <IconButton size="small" onClick={() => handleExpandRecentNotes(notes)}>
                                                                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            );
                        })}
                    <div style={{ marginTop: "20px" }} className={styles.dividerComponent} />
                    <div className={styles.bookmarkedNotesContainer}>
                        <div className={styles.bookmarkedNotesDiv}>
                            <IconButton
                                size="medium"
                                className={styles.addTimerIcon}
                            /*onClick={(event) => handleAddNewNotes(event)}*/
                            >
                                <CalendarTodayIcon style={{ height: "16px", width: "16px", color: "#4E253A" }}></CalendarTodayIcon>
                            </IconButton>
                            Previous Months
                        </div>
                        <>
                            {previousMonthTimerData.timerMonthlyInfo &&
                                previousMonthTimerData.timerMonthlyInfo.map((monthlyData) => {
                                    if (currentMonth !== monthlyData.month) {
                                        return (
                                            <div className={styles.prevMonthDivWrapper}>
                                                <div
                                                    className={styles.monthStatusIndicator}
                                                    style={{
                                                        backgroundColor: monthlyData.totalDuration < 600 ? timerColors[2] : monthlyData.totalDuration < 1200 ? timerColors[1] : timerColors[0],
                                                    }}
                                                ></div>
                                                <div className={styles.monthNameDiv}>{monthlyData.month}</div>
                                                <div className={styles.monthTimeDiv}>
                                                    <div className={styles.sessionTimeDiv}>
                                                        <div className={styles.timeTitle}>Monthly Time</div>
                                                        <div className={styles.timeDisplay}>{formatTime(monthlyData.totalDuration)}</div>
                                                        <div className={styles.timeLabels}>MIN SEC</div>
                                                    </div>
                                                    <div className={styles.expandButtonWrapper}>
                                                        <IconButton size="small">
                                                            <OpenInNewIcon style={{ height: "16px", width: "16px" }}></OpenInNewIcon>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                        </>
                        {/* <div className={styles.drawerFooter}>
                            <div style={{ color: "#FFFFFF" }}>View all Logs</div>
                        </div> */}
                        <div className={styles.drawerFooterDisabled} >
                            <div style={{ color: "#FFFFFF" }}>View all Logs</div>
                        </div>
                    </div>
                </>)}
        </Drawer>
    );
}
