import React from "react";
import styles from "./MlLogsHeader.module.css";
import HEADER from "../../../../../../Assets/Images/HEADER-Device Assignment.png";
import Grid from "@material-ui/core/Grid";
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../../../../Assets/Images/ProfileIcon.png";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../../../../Reducer/selectors/DashboardSelector";

export default function MlLogsHeader(props) {

  const userProfile = useSelector(getUserProfile);

  const getUserDisplayName = (firstName, lastName) => {
    return firstName.substring(0, 1) + '. ' + lastName;
  };


  return (
    <div className={styles.accContainer}>
      <Grid container>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.displayContainer}>
            <div className={styles.pageHeader}>
              <HubOutlinedIcon style={{ color: "#4E253A", height: "25px", width: "25px" }}></HubOutlinedIcon>
              <h3> Device Assignment </h3>
            </div>
            <div className={styles.userGreetings}>
              <div className={styles.userInfoDiv}>
                <div>
                  <Avatar className={styles.avatar2} alt="avatar_patient" src={ProfileIcon}></Avatar>
                </div>
                <div className={styles.displayName}>
                  {userProfile.firstName ? getUserDisplayName(userProfile.firstName, userProfile.lastName) : ''}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}