import { put, takeLatest } from "redux-saga/effects";
import * as patientActions from "../Actions/PatientActions";
import * as dashboardActions from "../Actions/DashboardActions";
// import axios from "axios";
import Transport from "../Transport/AidarTransport";
import { setPRMessage, setPRSuccess, setShowDiagnosesLoader, setShowEmailExistsLoader, setShowLoader, setShowPRLoader, setShowPatientGraphsLoader, setShowPatientProfilePageLoader, setShowPatientQuickViewLoader, setShowPatientVitalsLoader, setShowThresholdsLoader, setShowTrendsLoader } from "../Actions/UtilActions";
import domainCF from "../Components/API/domainCF";
import { dashboardConstants } from "../Constants";
import { useSelector } from "react-redux";
import { getTimerId } from "../Reducer/selectors/DashboardSelector";
import { getTimerInfo } from "../Actions";
import { getRegPatientFinalName, prepTrendsPayload } from "../Utils/GlobalFunctions";

function* getPatientListSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_SUBJECTS_BY_PROJECT_AND_LOCATION = `${domainCF}/api/getSubjectsByProjectAndLocation?projectId=${action.payload.projectId}&locationId=${action.payload.locationId}`;

  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_SUBJECTS_BY_PROJECT_AND_LOCATION, headers, {}, "GET");
    console.log(" GET AIDAR PATIENTS RESPONSE: ", response);
    if (!response) {
      yield put(patientActions.getPatientsListFailure());
    } else {
      yield put(patientActions.getPatientsListSuccess(response));
    }

    yield put(setShowLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* getPatientVitalsSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const GET_LATEST_VITALS = `${domainCF}/api/getLatestVitals?patientGuid=${action.payload}`;

  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_LATEST_VITALS, headers, {}, "GET");
    console.log('/api/getLatestVitals response: ', response);

    if (!response) {
      yield put(patientActions.getPatientsVitalsFailure());
    } else {
      yield put(patientActions.getPatientsVitalsSuccess(response));
    }

    yield put(setShowLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* getSubjectVitalsSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  //console.log('6...',action.payload)
  const GET_SUBJECT_VITALS = `${domainCF}/api/getAllSubjectVitalsFast?patientGuid=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;
  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_SUBJECT_VITALS, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getSubectVitalsListFailure());
    } else {
      yield put(patientActions.getSubjectVitalsListSuccess(response));
    }

    yield put(setShowLoader(false));
    yield put(setShowPatientVitalsLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    yield put(setShowPatientVitalsLoader(false));
    console.error("getSubjectVitalsSaga ERROR ", e);
    throw e;
  }
}

function* getEcgPlotSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SUBJECT_ECG_PLOT = `${domainCF}/api/getECGPlots?patientGuid=${action.payload.subjectGUID}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SUBJECT_ECG_PLOT, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getEcgPlotFailure());
    } else {
      console.log("ECG Plot response ", response);
      yield put(patientActions.getEcgPlotSuccess(response));
    }
    yield put(setShowPatientGraphsLoader(false));
  } catch (e) {
    yield put(setShowPatientGraphsLoader(false));
    console.error("getEcgPlotSaga ERROR ", e);
    throw e;
  }
}

function* getRfmPlotSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SUBJECT_RFM_PLOT = `${domainCF}/api/getRFMPlots?patientGuid=${action.payload.subjectGUID}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SUBJECT_RFM_PLOT, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getRfmPlotFailure());
    } else {
      console.log("RFM Plot response ", response);
      yield put(patientActions.getRfmPlotSuccess(response));
    }
    yield put(setShowPatientGraphsLoader(false));
  } catch (e) {
    yield put(setShowPatientGraphsLoader(false));
    console.error("getRfmPlotSaga ERROR ", e);
    throw e;
  }
}

function* getLfgPlotSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SUBJECT_LFG_PLOT = `${domainCF}/api/getLFPlots?patientGuid=${action.payload.subjectGUID}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SUBJECT_LFG_PLOT, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getLfgPlotFailure());
    } else {
      console.log("LFG Plot response ", response);
      yield put(patientActions.getLfgPlotSuccess(response));
    }
    yield put(setShowPatientGraphsLoader(false));
  } catch (e) {
    yield put(setShowPatientGraphsLoader(false));
    console.error("getLfgPlotSaga ERROR ", e);
    throw e;
  }
}

function* getMouthLabLogsSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_MOUTHLAB_LOGS = `${domainCF}/api/getDevicesByProjectAndLocation?projectId=${action.payload.projectId}&locationId=${action.payload.locationId}`;
  try {
    const response = yield Transport.genericOperation(GET_MOUTHLAB_LOGS, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getEcgPlotFailure());
    } else {
      console.log("MouthLab Logs response ", response);
      yield put(patientActions.getMouthLabLogSuccess(response));
    }
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* getAddSubjectSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const ADD_SUBJECT_URL = `${domainCF}/api/addSubject`;

  try {
    const response = yield Transport.genericOperation(ADD_SUBJECT_URL, headers, action.payload, "POST");

    if (!response) {
      yield put(patientActions.getAddSubjectFailure());
    } else {
      console.log("Add Subject response ", response);
      yield put(patientActions.getAddSubjectSuccess(response));
    }
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* putStartTimerSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const ADD_TIMER_URL = `${domainCF}/api/addTimer`;

  try {
    const response = yield Transport.genericOperation(ADD_TIMER_URL, headers, action.payload, "POST");

    if (!response) {
      yield put(patientActions.putStartTimerFailure());
    } else {
      console.log("Add Timer response ", response);
      yield put(patientActions.putStartTimerSuccess(response));
      yield put(dashboardActions.setTimerID(response.timerId));
    }
  } catch (e) {
    console.error("putStartTimerSaga Saga ERROR ", e);
    throw e;
  }
}

function* setNewThresholdSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const SET_NEW_THRESHOLD_URL = `${domainCF}/api/setNewThreshold`;
  console.log("SET_NEW_THRESHOLD_URL: ", SET_NEW_THRESHOLD_URL);

  try {
    const response = yield Transport.genericOperation(SET_NEW_THRESHOLD_URL, headers, action.payload, "POST");
    console.log("SET_NEW_THRESHOLD response: ", response);

    if (!response) {
      yield put(patientActions.setNewThresholdFailure());
    } else {
      console.log("setNewThreshold response ", response);
      yield put(patientActions.setNewThresholdSuccess(response));
    }
  } catch (e) {
    console.error("setNewThresholdSaga ERROR ", e);
    throw e;
  }
}

function* putEndTimerSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const END_TIMER_URL = `${domainCF}/api/updateTimerWithNote/`;

  const new_payload = {
    "timerId": action.payload.timerId,
    "subjectId": action.payload.subjectId,
    "userId": action.payload.userId,
    "end_time": action.payload.end_time,
    "duration": action.payload.duration,
    "status": action.payload.status,
    "note": action.payload.note,
    "tags": action.payload.tags,
    "createdBy": action.payload.createdBy,
    "createdDateTime": action.payload.createdDateTime,
    "timezoneStr": action.payload.timezoneStr,
  }

  try {
    const response = yield Transport.genericOperation(END_TIMER_URL, headers, new_payload, "POST");

    if (!response) {
      yield put(patientActions.putEndTimerFailure());
    } else {
      console.log("End Timer response ", response);
      yield put(patientActions.putEndTimerSuccess(response));
      yield put(getTimerInfo({ userId: action.payload.userId, subjectGuid: action.payload.subjectGuid }));
    }
  } catch (e) {
    console.error("putEndTimerSaga Saga ERROR ", e);
    throw e;
  }
}

function* putAbortedEndTimerSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const END_TIMER_URL = `${domainCF}/api/updateTimer/`;

  const new_payload = {
    "timerId": action.payload.timerId,
    "subjectId": action.payload.subjectId,
    "userId": action.payload.userId,
    "end_time": action.payload.end_time,
    "duration": action.payload.duration,
    "status": action.payload.status,
  }

  try {
    const response = yield Transport.genericOperation(END_TIMER_URL, headers, new_payload, "POST");

    if (!response) {
      yield put(patientActions.putAbortedEndTimerFailure());
    } else {
      console.log("End Timer response ", response);
      yield put(patientActions.putAbortedEndTimerSuccess(response));
      //    yield put(getTimerInfo({userId: action.payload.userId,subjectGuid: action.payload.subjectGuid}));
    }
  } catch (e) {
    console.error("putAbortedEndTimerSaga Saga ERROR ", e);
    throw e;
  }
}

function* getLatestBaselineSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  //console.log('baseline...',action.payload)
  const GET_LATEST_BASELINE = `${domainCF}/api/getLatestBaseline?subjectGuid=${action.payload.subjectGuid}`;
  try {
    const response = yield Transport.genericOperation(GET_LATEST_BASELINE, headers, {}, "GET");

    if (!response) {
      yield put(patientActions.getLatestBaselineFailure());
      yield put(setShowThresholdsLoader(false));
      yield put(setShowPatientQuickViewLoader(false));
    } else {
      //console.log("Baseline response ", response);
      yield put(patientActions.getLatestBaselineSuccess(response));
      yield put(setShowThresholdsLoader(false));
      yield put(setShowPatientQuickViewLoader(false));
    }

  } catch (e) {
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }

}

function* getSbpPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_SBP_PAYLOAD_URL = `${domainCF}/getSbpPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_SBP_PAYLOAD_URL, headers, {}, "GET");
    console.log("getSbpPayload response: ", response);
    if (!response) {
      yield put(patientActions.getSbpPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getSbpPayloadSuccess(finalPayload));
      console.log("sbp finalPayload: ", finalPayload);
      if (finalPayload[0].length > 7) {
        console.log("sbp finalPayload[0] length > 1");
        yield put(patientActions.setBpComputed(true));
      } else {
        yield put(patientActions.setBpComputed(false));
      }
    }
  } catch (e) {
    console.error("getSbpPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getDbpPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_DBP_PAYLOAD_URL = `${domainCF}/getDbpPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_DBP_PAYLOAD_URL, headers, {}, "GET");
    console.log("getDbpPayload response: ", response);
    if (!response) {
      yield put(patientActions.getDbpPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getDbpPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setBpComputed(true));
      } else {
        yield put(patientActions.setBpComputed(false));
      }
    }
  } catch (e) {
    console.error("getDbpPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getSpo2PayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_PAYLOAD_URL = `${domainCF}/getSpo2PayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_PAYLOAD_URL, headers, {}, "GET");
    console.log("getSpo2Payload response: ", response);
    if (!response) {
      yield put(patientActions.getSpo2PayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getSpo2PayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setSpo2BrComputed(true));
      } else {
        yield put(patientActions.setSpo2BrComputed(false));
      }
    }
  } catch (e) {
    console.error("getSpo2PayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getBrPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_BR_PAYLOAD_URL = `${domainCF}/getBrPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_BR_PAYLOAD_URL, headers, {}, "GET");
    console.log("getBrPayload response: ", response);
    if (!response) {
      yield put(patientActions.getBrPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getBrPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setSpo2BrComputed(true));
      } else {
        yield put(patientActions.setSpo2BrComputed(false));
      }
    }
  } catch (e) {
    console.error("getBrPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getHrPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_HR_PAYLOAD_URL = `${domainCF}/getHrPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_HR_PAYLOAD_URL, headers, {}, "GET");
    console.log("getHrPayload response: ", response);
    if (!response) {
      yield put(patientActions.getHrPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getHrPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setHrHrvComputed(true));
      } else {
        yield put(patientActions.setHrHrvComputed(false));
      }
    }
  } catch (e) {
    console.error("getHrPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getHrvPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_HRV_PAYLOAD_URL = `${domainCF}/getHrvPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_HRV_PAYLOAD_URL, headers, {}, "GET");
    console.log("getHrvPayload response: ", response);
    if (!response) {
      yield put(patientActions.getHrvPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getHrvPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setHrHrvComputed(true));
      } else {
        yield put(patientActions.setHrHrvComputed(false));
      }
    }
  } catch (e) {
    console.error("getHrvPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getTempPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_TEMP_PAYLOAD_URL = `${domainCF}/getTempPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_TEMP_PAYLOAD_URL, headers, {}, "GET");
    console.log("getTempPayload response: ", response);
    if (!response) {
      yield put(patientActions.getTempPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getTempPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setTempPrComputed(true));
      } else {
        yield put(patientActions.setTempPrComputed(false));
      }
    }
  } catch (e) {
    console.error("getTempPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getPrPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_PR_PAYLOAD_URL = `${domainCF}/getPrPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_PR_PAYLOAD_URL, headers, {}, "GET");
    console.log("getPrPayload response: ", response);
    if (!response) {
      yield put(patientActions.getPrPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getPrPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setTempPrComputed(true));
      } else {
        yield put(patientActions.setTempPrComputed(false));
      }
    }
  } catch (e) {
    console.error("getPrPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getFevPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_FEV_PAYLOAD_URL = `${domainCF}/getFev1PayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;
  console.log("GET_FEV_PAYLOAD_URL: ", GET_FEV_PAYLOAD_URL);
  try {
    const response = yield Transport.genericOperation(GET_FEV_PAYLOAD_URL, headers, {}, "GET");
    console.log("getFevPayload response: ", response);
    if (!response) {
      yield put(patientActions.getFevPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getFevPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setFevPefComputed(true));
      } else {
        yield put(patientActions.setFevPefComputed(false));
      }
    }
  } catch (e) {
    console.error("getFevPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getPefPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_PEF_PAYLOAD_URL = `${domainCF}/getPefPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_PEF_PAYLOAD_URL, headers, {}, "GET");
    console.log("getPefPayload response: ", response);
    if (!response) {
      yield put(patientActions.getPefPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getPefPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setFevPefComputed(true));
      } else {
        yield put(patientActions.setFevPefComputed(false));
      }
    }
  } catch (e) {
    console.error("getPefPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getGlucosePayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_GLUCOSE_PAYLOAD_URL = `${domainCF}/getGlucosePayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_GLUCOSE_PAYLOAD_URL, headers, {}, "GET");
    console.log("getGlucosePayload response: ", response);
    if (!response) {
      yield put(patientActions.getGlucosePayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getGlucosePayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setGwpComputed(true));
      } else {
        yield put(patientActions.setGwpComputed(false));
      }
    }
  } catch (e) {
    console.error("getGlucosePayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getWeightPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_WEIGHT_PAYLOAD_URL = `${domainCF}/getWeightPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_WEIGHT_PAYLOAD_URL, headers, {}, "GET");
    console.log("getWeightPayload response: ", response);
    if (!response) {
      yield put(patientActions.getWeightPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getWeightPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setGwpComputed(true));
      } else {
        yield put(patientActions.setGwpComputed(false));
      }
    }
  } catch (e) {
    console.error("getWeightPayloadSaga Saga ERROR ", e);
    throw e;
  }
}


function* getPainPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_PAIN_PAYLOAD_URL = `${domainCF}/getPainPayloadBySubjectIdWithRangeArray?subjectId=${action.payload.subjectId}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`;

  try {
    const response = yield Transport.genericOperation(GET_PAIN_PAYLOAD_URL, headers, {}, "GET");
    console.log("getPainPayload response: ", response);
    if (!response) {
      yield put(patientActions.getPainPayloadFailure());
    } else {
      let finalPayload = prepTrendsPayload(response.trends8Marrays);
      yield put(patientActions.getPainPayloadSuccess(finalPayload));
      if (finalPayload[0].length > 7) {
        yield put(patientActions.setGwpComputed(true));
      } else {
        yield put(patientActions.setGwpComputed(false));
      }
    }
  } catch (e) {
    console.error("getPainPayloadSaga Saga ERROR ", e);
    throw e;
  }
  yield put(setShowTrendsLoader(false));
};


function* getFacilityInfoSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_FACILITY_INFO_URL = `${domainCF}/api/getFacilityinfoauto?locationId=${action.payload.locationId}`;

  try {
    const response = yield Transport.genericOperation(GET_FACILITY_INFO_URL, headers, action.payload, "POST");
    console.log("getFacilityInfo response: ", response);
    if (!response) {
      yield put(patientActions.getFacilityInfoFailure());
    } else {
      let facilityInfoP = {
        name: response.name,
        number: response.number,
        type: response.type,
      };
      yield put(patientActions.getFacilityInfoSuccess(facilityInfoP));
    }
  } catch (e) {
    console.error("getPainPayloadSaga Saga ERROR ", e);
    throw e;
  }
};

function* getIfEmailAlreadyExistsSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_IF_EMAIL_ALREADY_EXISTS_URL = `${domainCF}/api/doesEmailAlreadyExist`;

  try {
    const response = yield Transport.genericOperation(GET_IF_EMAIL_ALREADY_EXISTS_URL, headers, action.payload, "POST");
    console.log("getIfEmailAlreadyExists response: ", response);
    yield put(patientActions.getIfEmailAlreadyExistsSuccess(response));
  } catch (e) {
    console.error("getIfEmailAlreadyExists Saga ERROR ", e);
    throw e;
  }

  yield put(setShowEmailExistsLoader(false));
};

function* getPatientInfoSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_PATIENT_INFO_URL = `${domainCF}/api/getSubjectByGuid?subjectGuid=${action.payload}`;

  try {
    const response = yield Transport.genericOperation(GET_PATIENT_INFO_URL, headers, {}, "GET");
    console.log("getPatientInfo Saga response: ", response);
    yield put(patientActions.getPatientInfoSuccess(response.subjects[0]));
    yield put(dashboardActions.setCurrSubjectStatus(response.subjects[0].status));
    yield put(dashboardActions.setSelectedSubjectId(response.subjects[0].subjectId));
    yield put(dashboardActions.setSelectedMlId(response.subjects[0].hasOwnProperty("deviceId")
      ? response.subjects[0].deviceId
      : "None"));
    yield put(setShowPatientProfilePageLoader(false));
  } catch (e) {
    console.error("getPatientInfo Saga ERROR ", e);
    throw e;
  }

  yield put(setShowPatientProfilePageLoader(false));
};

// const sleep = ms => new Promise(r => setTimeout(r, ms));


function* registerNewPatientSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const REGISTER_NEW_PATIENT_URL = `${domainCF}/api/addSubjectInfo`;

  try {
    const response = yield Transport.genericOperation(REGISTER_NEW_PATIENT_URL, headers, action.payload, "POST");

    console.log("Register New Patient response ", response);

    if (!response) {
      yield put(patientActions.registerNewPatientFailure());
      yield put(setPRSuccess(false));
      yield put(setPRMessage('Patient Registration failed! Please Retry'));
    } else {
      yield put(patientActions.registerNewPatientSuccess(response));
      yield put(setPRSuccess(response.success));
      yield put(setPRMessage(getRegPatientFinalName(action.payload.subject.contactInfo.prefix, action.payload.subject.contactInfo.firstName, action.payload.subject.contactInfo.lastName, action.payload.subject.contactInfo.suffix)));
    }
  } catch (e) {
    console.error("registerNewPatientSaga Saga ERROR ", e);
    throw e;
  }

  yield put(setShowPRLoader(false));
};


function* setPatientStatusSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const SET_PATIENT_STATUS_URL = `${domainCF}/api/setPatientStatus`;

  try {
    const response = yield Transport.genericOperation(SET_PATIENT_STATUS_URL, headers, action.payload, "POST");

    if (!response) {
      yield put(patientActions.setPatientStatusFailure());
    } else {
      console.log("setPatientStatus response: ", response);
      yield put(dashboardActions.setCurrSubjectStatus(action.payload.status));
      yield put(patientActions.setPatientStatusSuccess(response));
    }
  } catch (e) {
    console.error("setPatientStatusSaga Saga ERROR ", e);
    throw e;
  }
};

function* getDiagnosesPayloadSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const GET_DIAGNOSES_PAYLOAD_URL = `${domainCF}/api/getDiagnoses?subjectId=${action.payload.subjectId}`;

  try {
    const response = yield Transport.genericOperation(GET_DIAGNOSES_PAYLOAD_URL, headers, {}, "GET");
    console.log("getDiagnosesPayload response: ", response);
    if (!response) {
      yield put(patientActions.getDiagnosesPayloadFailure());
    } else {
      yield put(patientActions.getDiagnosesPayloadSuccess(response.diagnoses));
    }
  } catch (e) {
    console.error("getDiagnosesPayload Saga ERROR ", e);
    throw e;
  }
  yield put(setShowDiagnosesLoader(false));
}


export default function* PatientSaga() {
  try {
    yield takeLatest(patientActions.getPatientsList, getPatientListSaga);
    yield takeLatest(patientActions.getPatientVitalsList, getPatientVitalsSaga);
    yield takeLatest(patientActions.getSubjectVitalsList, getSubjectVitalsSaga);
    yield takeLatest(patientActions.getEcgPlot, getEcgPlotSaga);
    yield takeLatest(patientActions.getRfmPlot, getRfmPlotSaga);
    yield takeLatest(patientActions.getLfgPlot, getLfgPlotSaga);
    yield takeLatest(patientActions.getMouthLabLogs, getMouthLabLogsSaga);
    yield takeLatest(patientActions.getAddSubject, getAddSubjectSaga);
    yield takeLatest(patientActions.putStartTimer, putStartTimerSaga);
    yield takeLatest(patientActions.putEndTimer, putEndTimerSaga);
    yield takeLatest(patientActions.putAbortedEndTimer, putAbortedEndTimerSaga);
    yield takeLatest(patientActions.getLatestBaseline, getLatestBaselineSaga);
    yield takeLatest(patientActions.getSbpPayload, getSbpPayloadSaga);
    yield takeLatest(patientActions.getDbpPayload, getDbpPayloadSaga);
    yield takeLatest(patientActions.getSpo2Payload, getSpo2PayloadSaga);
    yield takeLatest(patientActions.getBrPayload, getBrPayloadSaga);
    yield takeLatest(patientActions.getHrPayload, getHrPayloadSaga);
    yield takeLatest(patientActions.getHrvPayload, getHrvPayloadSaga);
    yield takeLatest(patientActions.getTempPayload, getTempPayloadSaga);
    yield takeLatest(patientActions.getPrPayload, getPrPayloadSaga);
    yield takeLatest(patientActions.getFevPayload, getFevPayloadSaga);
    yield takeLatest(patientActions.getPefPayload, getPefPayloadSaga);
    yield takeLatest(patientActions.getGlucosePayload, getGlucosePayloadSaga);
    yield takeLatest(patientActions.getWeightPayload, getWeightPayloadSaga);
    yield takeLatest(patientActions.getPainPayload, getPainPayloadSaga);
    yield takeLatest(patientActions.setNewThreshold, setNewThresholdSaga);
    yield takeLatest(patientActions.registerNewPatient, registerNewPatientSaga);
    yield takeLatest(patientActions.getFacilityInfo, getFacilityInfoSaga);
    yield takeLatest(patientActions.getIfEmailAlreadyExists, getIfEmailAlreadyExistsSaga);
    yield takeLatest(patientActions.getPatientInfo, getPatientInfoSaga);
    yield takeLatest(patientActions.setPatientStatus, setPatientStatusSaga);
    yield takeLatest(patientActions.getDiagnosesPayload, getDiagnosesPayloadSaga);

  } catch {
    yield;
  }
}
