import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./PatientDiagnosesTab.module.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DiagnosesTable from "./DiagnosesTable";

const PatientDiagnosesTab = forwardRef((props, ref) => {

    const [isExpand, setIsExpand] = useState(true);

    const handleExpandClick = (event) => {
        event.preventDefault();
        setIsExpand(!isExpand);
    }

    return (
        <>
            <div className={styles.containerDiv}>
                <div className={styles.containerHeader}>
                    <div>

                    </div>
                    <div className={styles.headerText}>
                        Diagnosis
                    </div>
                    <div className={styles.expandButtonDiv} onClick={(event) => handleExpandClick(event)}>
                        {isExpand ? <KeyboardArrowUpIcon sx={{ color: "#4e253a" }} /> : <KeyboardArrowDownIcon sx={{ color: "#4e253a" }} />}
                    </div>
                </div>
                <div className={styles.containerBody}>
                    {isExpand ? <DiagnosesTable></DiagnosesTable> : <></>}
                </div>
            </div>
            <div className={styles.containerFooter}>
                <div className={styles.addDiagnosisDiv}>
                    + Add Diagnosis
                </div>
            </div>
        </>
    );
});

export default PatientDiagnosesTab;