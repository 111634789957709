import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  // Old UserPool
  //   UserPoolId: "us-west-2_2CJD0jN9F",
  //   ClientId: "2j80qbqbch8tnr1409h7562j72",

  // Wakemed  UserPool
  // UserPoolId: "us-west-2_5bnUwUPx8",
  // ClientId: "5p80iu64ksgnpuc0qtt272b4fa",

  // Aidar Connect  UserPool (prod)
    UserPoolId: "us-west-2_5bnUwUPx8",
    ClientId: "7pn90sfbdk9iq7jmo6o0j5bii1",

  // Aidar Connect Dev  UserPool
//   UserPoolId: "us-west-2_KUxpNEIoV",
//   ClientId: "7h24cus8o5kva0hj60c4h03skr",
};

export default new CognitoUserPool(poolData);
