import { call, put, takeLatest, select } from "redux-saga/effects";
// import axios from "axios";
import Transport from "../Transport/AidarTransport";
// import {setPa } from "../Actions/UtilActions";
import domainCF from "../Components/API/domainCF";
import * as notesActions from '../Actions';
import { setShowLoader, setShowPatientNotesLoader } from "../Actions/UtilActions";
import { selectedRecordIdForNotes, setOpenAddNotesPanel, setSelectedTimestamp, setShowNotesDrawer, setShowNotesPanel } from "../Actions";
import { selectedRecordIdForNotesSelector } from "../Reducer/selectors/NotesSelector";

function* getPatientNotesSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const GET_PATIENT_NOTES = `${domainCF}/api/getSubjectVitalsNotesBySubjectIdAndRecordId?subjectId=${action.payload.subjectId}&recordId=${action.payload.recordId}`;
   
    try {
      // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
      const response = yield Transport.genericOperation(GET_PATIENT_NOTES, headers, {}, 'GET');
  
      if (!response) {
        yield put(notesActions.getPatientNotesFailure());
      }else{
        yield put(notesActions.getPatientNotesSuccess(response));
      }
      yield put(setShowLoader(false));
      yield put(setShowPatientNotesLoader(false));
    } catch (e) {
      yield put(setShowLoader(false));
      yield put(setShowPatientNotesLoader(false));
      console.error("getChildInfoSaga Saga ERROR ", e);
      throw e;
    }
}

function* postPatientNotesSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const POST_PATIENT_NOTES = `${domainCF}/api/addSubjectVitalsNote`;
    console.log("POST NOTES PAYLOAD: ", action.payload);
   
    try {
      // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
      const response = yield Transport.genericOperation(POST_PATIENT_NOTES, headers, action.payload, "POST");
      console.log("POST NOTES RESPONSE: ", response);

      if (!response) {
        yield put(notesActions.postPatientNotesFailure());
      }else{
        yield put(notesActions.postPatientNotesSuccess(response));
        const req = {
            subjectId: action.payload.subjectId,
            recordId:''
        }
        yield put(notesActions.getPatientNotes(req));
      }
      yield put(setShowPatientNotesLoader(false));
    } catch (e) {
      yield put(setShowPatientNotesLoader(false));
      console.error("getChildInfoSaga Saga ERROR ", e);
      throw e;
    }
}

function* updatePatientNoteSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const UPDATE_PATIENT_NOTE = `${domainCF}/api/updateSubjectVitalsNote`;
   
    try {
      const response = yield Transport.genericOperation(UPDATE_PATIENT_NOTE, headers, action.payload, "POST");
  
      if (!response) {
        yield put(notesActions.updatePatientNoteFailure());
      }else{
        const recordId = yield select(selectedRecordIdForNotesSelector);
        yield put(notesActions.updatePatientNoteSuccess(response));
        const req = {
            subjectId: action.payload.subjectId,
            recordId: recordId
        }
        yield put(notesActions.getPatientNotes(req));
      }
    } catch (e) {
      console.error("getChildInfoSaga Saga ERROR ", e);
      throw e;
    }
}

function* addNotesToRecordFromTableSaga(action){
  yield put(selectedRecordIdForNotes(action.payload.recordId));
  yield put(setShowNotesPanel(true));
  yield put(setShowNotesDrawer(true));
  yield put(setOpenAddNotesPanel(true));
  yield put(setSelectedTimestamp(action.payload.timeStamp));
}

export default function* notesSaga() {
    try {
      yield takeLatest(notesActions.getPatientNotes, getPatientNotesSaga);
      yield takeLatest(notesActions.postPatientNotes,postPatientNotesSaga);
      yield takeLatest(notesActions.updatePatientNote,updatePatientNoteSaga);
      yield takeLatest(notesActions.addNotesToRecordFromTable,addNotesToRecordFromTableSaga);
    } catch {
      yield;
    }
  }