export const utilsConstants = {
  SET_SHOW_LOADER: "SET_SHOW_LOADER",
  SET_SHOW_PATIENT_PROFILE_PAGE_LOADER: 'SET_SHOW_PATIENT_PROFILE_PAGE_LOADER',
  SET_SHOW_PATIENT_TIMER_DRAWER_LOADER: 'SET_SHOW_PATIENT_TIMER_DRAWER_LOADER',
  SET_SHOW_PATIENT_GRAPHS_LOADER: 'SET_SHOW_PATIENT_GRAPHS_LOADER',
  SET_SHOW_PATIENT_AIDI_LOADER: 'SET_SHOW_PATIENT_AIDI_LOADER',
  SET_SHOW_AIDI_VALIDATION_LOADER: 'SET_SHOW_AIDI_VALIDATION_LOADER',
  SET_SHOW_PATIENT_VITALS_LOADER: 'SET_SHOW_PATIENT_VITALS_LOADER',
  SET_SHOW_PATIENT_NOTES_LOADER: 'SET_SHOW_PATIENT_NOTES_LOADER',
  SET_SHOW_ASSIGN_ML_LOADER: 'SET_SHOW_ASSIGN_ML_LOADER',
  SET_SHOW_THRESHOLDS_LOADER: 'SET_SHOW_THRESHOLDS_LOADER',
  SET_SHOW_PR_LOADER: 'SET_SHOW_PR_LOADER',
  SET_SHOW_QUICKVIEW_LOADER: 'SET_SHOW_QUICKVIEW_LOADER',
  SET_SHOW_EMAIL_EXISTS_LOADER: 'SET_SHOW_EMAIL_EXISTS_LOADER',
  SET_SHOW_TRENDS_LOADER: 'SET_SHOW_TRENDS_LOADER',
  SET_SHOW_DIAGNOSES_LOADER: 'SET_SHOW_DIAGNOSES_LOADER',

  //ML Vitals page current user
  SET_CURRENT_PATIENT_GUID: 'SET_CURRENT_PATIENT_GUID',
  SET_CURRENT_PATIENT_SUBJECTID: 'SET_CURRENT_PATIENT_SUBJECTID',

  // All patient details
  SET_ALL_PATIENTS_LIST: 'SET_ALL_PATIENTS_LIST',
  SET_PATIENT_MAINTAB: 'SET_PATIENT_MAINTAB',

  //Trends
  SET_SPO2_BR_EXPAND: 'SET_SPO2_BR_EXPAND',
  SET_HR_HRV_EXPAND: 'SET_HR_HRV_EXPAND',
  SET_BP_EXPAND: 'SET_BP_EXPAND',
  SET_TEMP_PR_EXPAND: 'SET_TEMP_PR_EXPAND',
  SET_FEV_PEF_EXPAND: 'SET_FEV_PEF_EXPAND',
  SET_G_W_P_EXPAND: 'SET_G_W_P_EXPAND',

  //Registration
  SET_REGISTRATION_STATUS: 'SET_REGISTRATION_STATUS',
  SET_REGISTRATION_STEP: 'SET_REGISTRATION_STEP',
  SET_PR_GENERAL_INFO: 'SET_PR_GENERAL_INFO',
  SET_PR_PAYER_INFO: 'SET_PR_PAYER_INFO',
  SET_PR_FAMILY_INFO: 'SET_PR_FAMILY_INFO',
  SET_PR_CARE_TEAM_INFO: 'SET_PR_CARE_TEAM_INFO',
  SET_PR_FACILITY_INFO: 'SET_PR_FACILITY_INFO',
  SET_PR_SUCCESS: 'SET_PR_SUCCESS',
  SET_PR_MESSAGE: 'SET_PR_MESSAGE',

};
