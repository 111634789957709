import styles from "./Timer.module.css";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton, Input } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useDispatch, useSelector } from "react-redux";
import { getShowNotesDrawer, getShowTimerDrawer, getTimerId, getTimerState, getUserProfile, getSelectedSubjectId, getShowTimerNotCompleteDialog, getShouldAbortTimer, getAbortTimerRedirectLink } from "../../../../Reducer/selectors/DashboardSelector";
import { putStartTimer, setIsTimerRunning, setTimerState, setTimerValue, putEndTimer, putAbortedEndTimer, setShouldAbortTimer, setAbortTimerRedirectLink } from "../../../../Actions";
import NotStartedIcon from '@mui/icons-material/NotStarted';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { getDisplayLabelWithoutEhrId } from "../../../../Utils/GlobalFunctions";
import { getTimerInfo } from "../../../../Actions";
import { getCurrentPatientGUID, getCurrentPatientSubjectID } from "../../../../Reducer/selectors/UtilSelector";
import { useNavigate, useParams } from "react-router-dom";

export default function Timer(props) {

    const timerState = useSelector(getTimerState);
    const dispatch = useDispatch();
    const [isAdherenceSelected, setIsAdherenceSelected] = useState(false);
    const [isMedicationSelected, setIsMedicationSelected] = useState(false);
    const [isVitalsSelected, setIsVitalsSelected] = useState(false);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [isOthersSelected, setIsOthersSelected] = useState(false);
    const [tagNames, setTagNames] = useState("");
    const [timerStartTime, setTimerStartTime] = useState(new Date());

    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;
    const patientName = getDisplayLabelWithoutEhrId(props.patientDetailInfo.contactInfo.firstName, props.patientDetailInfo.contactInfo.lastName, props.patientDetailInfo.subjectId);
    const timerId = useSelector(getTimerId);
    const isTimerDrawerOpen = useSelector(getShowTimerDrawer);
    const isNotesDrawerOpen = useSelector(getShowNotesDrawer);
    const selectedSubjectId = useSelector(getSelectedSubjectId);
    let curPatientId = useParams();
    //const patientInfo = useContext(PatientContext);
    let subjectId = props.patientDetailInfo.subjectId;
    const currPatientSubjectID = useSelector(getCurrentPatientSubjectID);
    const currPatientGUID = useSelector(getCurrentPatientGUID);
    const shouldIPauseTimer = useSelector(getShowTimerNotCompleteDialog);
    const shouldIAbortTimer = useSelector(getShouldAbortTimer);
    const abortTimerRedirectLink = useSelector(getAbortTimerRedirectLink);
    const navigate = useNavigate();

    const [newNotes, setNewNotes] = useState("");

    const [showElement, setShowElement] = React.useState(true);

    useEffect(() => {
        setTimeout(function () {
            setShowElement(false);
        }, 30000);
    }, []);

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: false });

    const startTimer = () => {
        resetNotesState();
        let payload = {
            "subjectId": subjectId,
            "userId": userProfile.id,
            "start_time": new Date(),
            "status": "Incomplete"
        }

        dispatch(putStartTimer(payload));
        dispatch(setTimerState(1));
        start();
    }

    const pauseTimer = () => {
        if (isRunning) {
            pause();
        } else {
            start();
        }
    }

    const stopTimer = () => {
        if (isRunning) {
            pause();
        }
    }

    const resetNotesState = () => {
        setNewNotes('');
        //setBookmarked(0);
        setTagNames("");
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const stopTimerAndAddNotes = (popupState) => {
        setTagNames("");
        if (isRunning) {
            pause();
        }
        let payload = {
            "timerId": parseInt(timerId),
            "end_time": new Date(),
            "status": "Complete",
            "duration": totalSeconds,
            "note": newNotes,
            "tags": tagNames,
            "createdBy": userName,
            "createdDateTime": new Date(),
            "timezoneStr": timeZone,
            userId: userProfile.id,
            subjectId: selectedSubjectId.selectedSubjectId,
            subjectGuid: currPatientGUID,
        }

        dispatch(putEndTimer(payload));

        reset();
        setTagNames("");
        setIsAdherenceSelected(false);
        setIsMedicationSelected(false);
        setIsVitalsSelected(false);
        setIsDeviceSelected(false);
        setIsOthersSelected(false);
        dispatch(setTimerState(0));
        setNewNotes("");
        setTagNames("");
        popupState.close();

        const timerPayload = {
            userId: userProfile.id,
            subjectGuid: currPatientGUID,
        }
        console.log("timer subjectGuiD after stop timer: ", curPatientId.patientId);
        dispatch(getTimerInfo(timerPayload));
    }

    const handleAddTagAdherence = (event) => {
        event.preventDefault();
        setIsAdherenceSelected(!isAdherenceSelected);
        setTagNames("Adherence");
        /* if (tagNames.includes("Adherence")) {
            const filteredTags = tagNames.filter((item) => item !== "Adherence");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Adherence"]);
        } */
    }

    const handleAddTagMedication = (event) => {
        event.preventDefault();
        setIsMedicationSelected(!isMedicationSelected);
        setTagNames("Medication");
        /* if (tagNames.includes("Medication")) {
            const filteredTags = tagNames.filter((item) => item !== "Medication");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Medication"]);
        } */
    }

    const handleAddTagDevice = (event) => {
        event.preventDefault();
        setIsDeviceSelected(!isDeviceSelected);
        setTagNames("Device");
        /* if (tagNames.includes("Device")) {
            const filteredTags = tagNames.filter((item) => item !== "Device");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Device"]);
        } */
    }

    const handleAddTagOthers = (event) => {
        event.preventDefault();
        setIsOthersSelected(!isOthersSelected);
        setTagNames("Others");
        /* if (tagNames.includes("Others")) {
            const filteredTags = tagNames.filter((item) => item !== "Others");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Others"]);
        } */
    }

    const handleAddTagVitals = (event) => {
        event.preventDefault();
        setIsVitalsSelected(!isVitalsSelected);
        setTagNames("Vitals");
        /* if (tagNames.includes("Vitals")) {
            const filteredTags = tagNames.filter((item) => item !== "Vitals");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Vitals"]);
        } */
    }

    useEffect(() => {
        if (shouldIPauseTimer) {
            if (isRunning) {
                pause();
            }
        } else {
            start();
        }
    }, [shouldIPauseTimer]);

    useEffect(() => {
        dispatch(setIsTimerRunning(isRunning));
        dispatch(setTimerValue(totalSeconds));
    }, [isRunning, totalSeconds]);

    useEffect(() => {
        if (shouldIAbortTimer) {
            if (isRunning) {
                pause();
            }
            let payload = {
                "timerId": parseInt(timerId),
                "end_time": new Date(),
                "status": "Aborted",
                "duration": totalSeconds,
                userId: userProfile.id,
                subjectId: selectedSubjectId.selectedSubjectId,
            }

            dispatch(putAbortedEndTimer(payload));

            reset();
            setTagNames("");
            setIsAdherenceSelected(false);
            setIsMedicationSelected(false);
            setIsVitalsSelected(false);
            setIsDeviceSelected(false);
            setIsOthersSelected(false);
            dispatch(setTimerState(0));
            setNewNotes("");
            setTagNames("");
            if (abortTimerRedirectLink != "") {
                navigate(abortTimerRedirectLink);
            }
            dispatch(setShouldAbortTimer(false));
            dispatch(setAbortTimerRedirectLink(""));
        }
    }, [shouldIAbortTimer]);

    if (timerState === 0) {
        return (
            <div className={styles.topDiv}>
                <div className={styles.timerDiv}>
                    <div className={styles.startButton}>
                        <IconButton style={{ backgroundColor: "#e4f1e3", height: "35px", width: "35px" }} onClick={() => startTimer()}>
                            <PlayArrowIcon style={{ color: "#84d474" }}></PlayArrowIcon>
                        </IconButton>
                    </div>
                    <div className={styles.startTimer}>
                        Start Timer
                    </div>
                </div>
                {!(isTimerDrawerOpen || isNotesDrawerOpen) && showElement && <div className={styles.bubbleLeft}>
                    Do you want to start a session?
                </div>}
            </div>
        );
    } else if (timerState === 1) {
        return (
            <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                {(popupState) => (
                    <div className={styles.topDiv}>
                        <div className={styles.timerDiv}>
                            <div className={styles.addNotesButton}>
                                <IconButton className={styles.addNotesBG}>
                                    + Notes
                                </IconButton>
                            </div>
                            <div className={styles.timerValue}>
                                {minutes.toString().padStart(2, 0)} : {seconds.toString().padStart(2, 0)}
                            </div>
                            <IconButton style={{ backgroundColor: "#ffffff", height: "40px", width: "40px", padding: "0px !important" }} onClick={() => pauseTimer()}>
                                {isRunning ? (<PauseCircleFilledOutlinedIcon style={{ color: "#Ffd800", height: "40px", width: "40px" }}></PauseCircleFilledOutlinedIcon>) : (<NotStartedIcon style={{ color: "#84d474", height: "40px", width: "40px" }}></NotStartedIcon>)}
                            </IconButton>
                            <IconButton style={{ backgroundColor: "#edc9c9", height: "35px", width: "35px", padding: "0px !important" }} onClick={() => stopTimer()}>
                                <StopRoundedIcon style={{ color: "#fc5d56", height: "30px", width: "30px" }} {...bindToggle(popupState)}></StopRoundedIcon>
                            </IconButton>
                        </div>
                        <Popper {...bindPopper(popupState)} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper sx={{ p: 0, marginTop: 7, marginLeft: 13, marginRight: 1, border: "none" }}>
                                        {/* <PatientProfile patientDetailInfo={patientDetailInfo} popupState={popupState} elapsedHours={timeResult} /> */}
                                        <div className={styles.wrapper}>
                                            {/* <div className={styles.closeIcon}>
                                                <IconButton aria-label="delete">
                                                    <RiCloseCircleFill size={18} style={{ color: "#4E253A" }} onClick={popupState.close} />
                                                </IconButton>
                                            </div> */}
                                            <div className={styles.contentArea}>
                                                <div className={styles.headingDiv}>
                                                    <div className={styles.headingAndId}>
                                                        <div className={styles.heading}>
                                                            Log Time & Activity
                                                        </div>
                                                        <div className={styles.subjectId}>
                                                            {/* ADR {props.patientDetailInfo.subjectId} */}
                                                            {patientName}
                                                        </div>
                                                    </div>

                                                    <div className={styles.tagsDiv}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(event) => handleAddTagAdherence(event)}
                                                        >
                                                            <div className={isAdherenceSelected ? styles.selectedTagAdherence : styles.tagNameAdherence}>
                                                                Adherence
                                                            </div>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(event) => handleAddTagMedication(event)}
                                                        >
                                                            <div className={isMedicationSelected ? styles.selectedTagMedication : styles.tagNameMedication}>
                                                                Medication
                                                            </div>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(event) => handleAddTagVitals(event)}
                                                        >
                                                            <div className={isVitalsSelected ? styles.selectedTagVitals : styles.tagNameVitals}>
                                                                Vitals
                                                            </div>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(event) => handleAddTagDevice(event)}
                                                        >
                                                            <div className={isDeviceSelected ? styles.selectedTagDevice : styles.tagNameDevice}>
                                                                Device
                                                            </div>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(event) => handleAddTagOthers(event)}
                                                        >
                                                            <div className={isOthersSelected ? styles.selectedTagOthers : styles.tagNameOthers}>
                                                                Others
                                                            </div>
                                                        </IconButton>
                                                    </div>
                                                </div>

                                                <div className={styles.userName}>
                                                    {userName}
                                                </div>

                                                <Input
                                                    className={styles.notesEditor}
                                                    type="text"
                                                    placeholder="Start typing..."
                                                    value={newNotes}
                                                    multiline={true}
                                                    onChange={(event) => setNewNotes(event.target.value)}
                                                    disableUnderline="true" />

                                                <div className={styles.characterLimitDiv}>Character limit: {<div className={(newNotes.length <= 0 || newNotes.length > 1000) ? styles.characterCountRed : styles.characterCount}>{newNotes.length}</div>}/1000</div>

                                                <div className={styles.logTimeButtonDiv}>
                                                    <div className={styles.timernoteMessage1}>
                                                        Note: Please include activity notes before logging the time.
                                                    </div>
                                                    {(newNotes.length > 0 && newNotes.length <= 1000) ? (<div className={styles.logTimeButton} onClick={(event) => stopTimerAndAddNotes(popupState)}>
                                                        Log Time
                                                    </div>) : (<div className={styles.logTimeButtonDisabled}>
                                                        Log Time
                                                    </div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </div>
                )}
            </PopupState>
        );
    } else {
        return (<></>);
    }
}