import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import styles from "./NotesDrawer.module.css";
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@mui/material/IconButton";
import { setShowNotesPanel, setShowNotesDrawer, selectedRecordIdForNotes, setOpenAddNotesPanel, getPatientNotes, setSelectedTimestamp, postPatientNotes, updatePatientNote, setPostNotesSuccessFlag, setSelectedSubjectId } from "../../../../Actions";
import { getNotesTagsSelector, getSelectedNotesTimestamp } from "../../../../Reducer/selectors/NotesSelector";
import { getShowNotesPanel, getShowNotesDrawer, getCurSubjectName } from "../../../../Reducer/selectors/DashboardSelector";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getSelectedSubjectId } from "../../../../Reducer/selectors/DashboardSelector";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import Input from '@material-ui/core/Input';
import { setShowLoader } from "../../../../Actions/UtilActions";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { selectedRecordIdForNotesSelector, getPatientBookmarkedNotesSelector, getPatientNotesSelector, postPatientNotesSuccessorSelector, getOpenAddNotesPanelSelector } from "../../../../Reducer/selectors/NotesSelector";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate, useParams } from "react-router-dom";
import { PatientContext } from "../../../Store/PatientDataProvider";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getPatientInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import { getDisplayLabelWithEhrId, getDisplayLabelWithoutEhrId } from "../../../../Utils/GlobalFunctions";

export default function NotesDrawer() {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const timeZoneFromDayjs = dayjs.tz.guess();

    //const [patientDetailInfo, setPatientDetailInfo] = useState({});
    const [isNewNotes, setIsNewNotes] = useState(false);
    const [newNotes, setNewNotes] = useState("");
    const [bookmarked, setBookmarked] = useState(0);
    const [tagNames, setTagNames] = useState("");
    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;
    const [notes, setNotes] = useState([]);
    const [expandedRecentNoteId, setExpandedRecentNoteId] = useState('');
    const bookmarkedNotes = useSelector(getPatientBookmarkedNotesSelector);
    const [expandedBookmarkedNoteId, setExpandedBookmarkedNoteId] = useState('');
    const patientInfo = useContext(PatientContext);
    let subjectId = patientInfo.subjectId;
    let subjectGUID = patientInfo.subjectGUID;
    let deviceIdContext = patientInfo.currentMouthLabId;
    let refreshData = patientInfo.isRefresh;
    let curPatientId = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Selected Notes Timestamp
    let selectedRecordTS = useSelector(getSelectedNotesTimestamp);
    const selectedRecordId = useSelector(selectedRecordIdForNotesSelector);

    // Notes tags
    let selectedTagsState = useSelector(getNotesTagsSelector);

    // Notes drawer and panel actions
    let drawerOneAction = useSelector(getShowNotesPanel);
    let drawerTwoAction = useSelector(getShowNotesDrawer);

    let drawerState = {
        drawerOne: drawerOneAction,
        drawerTwo: drawerTwoAction,
    };

    const patientNotes = useSelector(getPatientNotesSelector);
    const patientNoteSuccessor = useSelector(postPatientNotesSuccessorSelector);
    const openAddNotesPanel = useSelector(getOpenAddNotesPanelSelector);

    const patientDetailInfo = useSelector(getPatientInfoSelector);

    const getPatientLabel = (patientDetailInfo) => {
        let displayName = "";
        if (patientDetailInfo.contactInfo.firstName != "") {
            // if (patientDetailInfo.ehrId != null && patientDetailInfo.ehrId != "") {
            //   return getDisplayLabelWithEhrId(patientDetailInfo.firstName, patientDetailInfo.lastName, patientDetailInfo.ehrId);
            // }
            let fullName = getDisplayLabelWithoutEhrId(patientDetailInfo.contactInfo.firstName, patientDetailInfo.contactInfo.lastName);
            if (fullName.length > 15) {
                // Trim the combined string to 15 characters and add ",.."
                //dispatch(setCurrSubjectName(fullName.substring(0, 15) + "...")); 
                return fullName.substring(0, 15) + "...";
            } else {
                // Use the full combined name
                //dispatch(setCurrSubjectName(fullName)); 
                return fullName;
            }
        }
        const subjectlabel = "ADR - " + patientDetailInfo.subjectId;
        return subjectlabel;
    };

    const curSubjectName = getPatientLabel(patientDetailInfo);

    const useStyles = makeStyles((theme) => ({
        patientDetailsDiv: {
            marginRight: drawerState => drawerState.drawerTwo
                ? '350px' :
                drawerState.drawerOne ? '50px' : '0px',

            overflowX: "hidden",

            transition: drawerState => !drawerState.drawerOne
                ? theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: "500ms",
                })
                : theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: "500ms",
                }),
        },

        primaryDrawer: {
            width: "50px",
            zIndex: 5,
            flexShrink: 0,
            '& .MuiDrawer-root': drawerState => drawerState.drawerTwo
                ? {
                    position: 'absolute',
                    marginRight: "300px",
                } : {
                    position: 'relative',
                    marginRight: "0px",
                },
            "& .MuiDrawer-paper": {
                backgroundColor: "#FCF9FA"
            },
            transition: drawerState => !drawerState.drawerTwo
                ? theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: "500ms",
                })
                : theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: "500ms",
                }),
        },

        primaryDrawerPaper: {
            width: "50px",
            boxSizing: "border-box",
            zIndex: 5,
            position: 'absolute',
            marginRight: drawerState => drawerState.drawerTwo
                ? "300px" : "0px",
            transition: drawerState => !drawerState.drawerTwo
                ? theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: "500ms",
                })
                : theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: "500ms",
                }),
        },

        secondaryDrawer: {
            maximumWidth: "300px",
            zIndex: 3,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                backgroundColor: "#F3E8EE"
            },
            transition: drawerState => !drawerState.drawerTwo
                ? theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: "500ms",
                })
                : theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: "500ms",
                }),
        },

        secondaryDrawerPaper: {
            width: "300px",
            boxSizing: "border-box",
            zIndex: 3,
            transition: drawerState => !drawerState.drawerTwo
                ? theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: "500ms",
                })
                : theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: "500ms",
                }),
        }

    }));

    const classes = useStyles(drawerState);

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end"
    }));

    const handleBookmarking = (event, notes) => {
        event.preventDefault();
        updateNote(notes);
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const updateNote = (notes) => {
        const payload = {
            id: notes.id,
            subjectId: notes.subjectId,
            recordId: notes.recordId,
            recordTimestamp: notes.recordTimestamp,
            // createdDateTime: notes.createdDateTime,
            createdDateTime: new Date(),
            createdBy: notes.createdBy,
            note: notes.note,
            isBookmarked: notes.isBookmarked == "false" ? 1 : 0,
            tags: notes.tags,
            timezoneStr: timeZone,
        }
        dispatch(updatePatientNote(payload));
    }

    const handleNotesDrawerOpen = (event) => {
        event.preventDefault();
        dispatch(setShowNotesPanel(true));
        dispatch(selectedRecordIdForNotes(''));
        dispatch(setShowNotesDrawer(!drawerState.drawerTwo));

        if (!drawerState.drawerTwo) {
            dispatch(setOpenAddNotesPanel(false));
            const payload = {
                subjectId: selectedSubjectId.selectedSubjectId,
                recordId: ''
            }
            dispatch(getPatientNotes(payload));
        }
    }

    const handleAddNewNotes = (event) => {
        event.preventDefault();
        dispatch(setOpenAddNotesPanel(true));
        setNewNotes("");
        setTagNames("");
        dispatch(setSelectedTimestamp(""));
    }

    const handleCancelAddNewNotes = (event) => {
        event.preventDefault();
        dispatch(setOpenAddNotesPanel(false));
        setNewNotes("");
        setTagNames("");
        dispatch(setSelectedTimestamp(""));
        dispatch(selectedRecordIdForNotes(''));
    }

    const handleAddTag = (tag) => {
        /* if (tagNames.includes(tag)) {
            const filteredTags = tagNames.filter((item) => item !== tag);
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, tag]);
        } */
        setTagNames(tag);
    }

    //console.log("timeZone:", timeZone);

    const submitNotes = () => {
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: selectedRecordId,
            recordTimestamp: selectedRecordTS,
            createdBy: userName,
            note: newNotes,
            isBookmarked: bookmarked,
            tags: tagNames,
            createdDateTime: new Date(),
            timezoneStr: timeZone,
        }
        dispatch(setShowLoader(true));
        dispatch(postPatientNotes(payload));
        dispatch(setOpenAddNotesPanel(false));
        setNewNotes("");
        setTagNames("");
        dispatch(setSelectedTimestamp(""));
        dispatch(selectedRecordIdForNotes(''));
    }

    const handleExpandRecentNotes = (note) => {
        if (expandedRecentNoteId === note.id) {
            setExpandedRecentNoteId("");
        } else {
            setExpandedRecentNoteId(note.id);
        }
    }

    const handleExpandBookmarkedNotes = (note) => {
        if (expandedBookmarkedNoteId === note.id) {
            setExpandedBookmarkedNoteId("");
        } else {
            setExpandedBookmarkedNoteId(note.id);
        }
    }

    const handleViewAllNotes = (event) => {
        event.preventDefault();
        dispatch(setOpenAddNotesPanel(false));
        dispatch(setShowNotesDrawer(false));
        dispatch(setShowNotesPanel(false));
        navigate(`/patientDetails/${subjectGUID}/notes/4`);
    }

    const resetNotesState = () => {
        setNewNotes('');
        setBookmarked(0);
        setTagNames("");
    }

    useEffect(() => {
        setNotes(patientNotes);
    }, [patientNotes]);

    useEffect(() => {
        resetNotesState();
        let pNotes = notes;
        let filteredNotes = [];

        if (selectedRecordId) {
            const findRecords = pNotes.filter(note => note.recordId === selectedRecordId);
            if (findRecords && findRecords.length > 0) {
                filteredNotes.push(...findRecords);
                findRecords.forEach(record => {
                    const index = pNotes.findIndex(notes => notes.id == record.id);
                    pNotes.splice(index, 1);
                })
                filteredNotes.push(...pNotes);
            }
            setNotes(filteredNotes);
        }
    }, [selectedRecordId]);

    useEffect(() => {
        const payload = {
            subjectId: subjectId || selectedSubjectId.selectedSubjectId,
            recordId: ''
        }
        dispatch(getPatientNotes(payload));
        dispatch(setOpenAddNotesPanel(false));
    }, []);

    useEffect(() => {
        if (patientNoteSuccessor) {
            resetNotesState();
            dispatch(setPostNotesSuccessFlag(false));
        }
    }, [patientNoteSuccessor]);

    useEffect(() => {
        // setOpenAddNotesPanel(openAddNotesPanel);
        setIsNewNotes(openAddNotesPanel);
    }, [openAddNotesPanel]);


    useEffect(() => {
        resetNotesState();
        GetPatientInfo(curPatientId.patientId).then((res) => {
            //setPatientDetailInfo(res);
            //console.log("PATIENT DETAILS: ", res);
            dispatch(setSelectedSubjectId(res.subjectId));
            const payload = {
                subjectId: res.subjectId,
                recordId: ''
            }
            dispatch(getPatientNotes(payload));
        });
    }, [curPatientId.patientId, deviceIdContext, refreshData]);

    function ColorfulTags() {
        //console.log("COLORFUL TAGS: ", selectedTagsState);

        tagNames === "Adherence" ?
            (<div className={styles.selectedTagAdherence} key={tagNames}> {tagNames} </div>) :
            tagNames === "Medication" ?
                (<div className={styles.selectedTagMedication} key={tagNames}> {tagNames} </div>) :
                tagNames === "Vitals" ?
                    (<div className={styles.selectedTagVitals} key={tagNames}> {tagNames} </div>) :
                    tagNames === "Device" ?
                        (<div className={styles.selectedTagDevice} key={tagNames}> {tagNames} </div>) :
                        tagNames === "Others" ?
                            (<div className={styles.selectedTagOthers} key={tagNames}> {tagNames} </div>) :
                            (<div className={styles.selectedTagName} key={tagNames}> {tagNames} </div>)

        /* if (setTagNames.length === 0) {
            return (
                <></>
            );
        }
        else {
            return (
                <>
                    {tagNames.map(tag => (
                        tag === "Adherence" ?
                            (<div className={styles.selectedTagAdherence} key={tag}> {tag} </div>) :
                            tag === "Medication" ?
                                (<div className={styles.selectedTagMedication} key={tag}> {tag} </div>) :
                                tag === "Vitals" ?
                                    (<div className={styles.selectedTagVitals} key={tag}> {tag} </div>) :
                                    tag === "Device" ?
                                        (<div className={styles.selectedTagDevice} key={tag}> {tag} </div>) :
                                        tag === "Others" ?
                                            (<div className={styles.selectedTagOthers} key={tag}> {tag} </div>) :
                                            (<div className={styles.selectedTagName} key={tag}> {tag} </div>)))}
                </>
            );
        } */
    }

    function ColorfulTagsGen(props) {

        if (!props.tags || props.tags.length === 0) {
            return (
                <></>
            );
        }
        else {
            return (
                <>
                    {/* props.tags.map(tag => ( */
                        props.tags === "Adherence" ?
                            (<div className={styles.selectedTagAdherence} key={props.tags}> {props.tags} </div>) :
                            props.tags === "Medication" ?
                                (<div className={styles.selectedTagMedication} key={props.tags}> {props.tags} </div>) :
                                props.tags === "Vitals" ?
                                    (<div className={styles.selectedTagVitals} key={props.tags}> {props.tags} </div>) :
                                    props.tags === "Device" ?
                                        (<div className={styles.selectedTagDevice} key={props.tags}> {props.tags} </div>) :
                                        props.tags === "Others" ?
                                            (<div className={styles.selectedTagOthers} key={props.tags}> {props.tags} </div>) :
                                            (<div className={styles.selectedTagName} key={props.tags}> {props.tags} </div>)//))
                    }
                </>
            );
        }
    }

    return (
        <Drawer
            className={classes.secondaryDrawer}
            classes={{
                paper: classes.secondaryDrawerPaper,
            }}
            variant="persistent"
            anchor="right"
            open={drawerState.drawerTwo}>
            <DrawerHeader className={styles.drawerHeader}>
                <div className={styles.headerLabel}>
                    Notes <br /> {curSubjectName}
                </div>
                <IconButton
                    size="large"
                    className={styles.noteDrawerCloseIcon}
                    onClick={(event) => handleNotesDrawerOpen(event)}>
                    <CancelIcon style={{ color: `#4E253A` }} />
                </IconButton>
            </DrawerHeader>
            <Divider />

            <div className={styles.addNotesDiv}>
                <IconButton
                    size="medium"
                    className={styles.addNotesIcon}
                    onClick={(event) => handleAddNewNotes(event)}
                >
                    <AddCircleOutlineIcon style={{ color: "#4E253A" }}></AddCircleOutlineIcon>
                </IconButton>
                Add Notes
            </div>

            {isNewNotes && (
                <>
                    <div className={styles.tagsMenu}>
                        <IconButton
                            size="small"
                            onClick={() => handleAddTag("Adherence")}
                        >
                            <div className={styles.tagNameAdherence}>
                                Adherence
                            </div>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => handleAddTag("Medication")}
                        >
                            <div className={styles.tagNameMedication}>
                                Medication
                            </div>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => handleAddTag("Vitals")}
                        >
                            <div className={styles.tagNameVitals}>
                                Vitals
                            </div>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => handleAddTag("Device")}
                        >
                            <div className={styles.tagNameDevice}>
                                Device
                            </div>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => handleAddTag("Others")}
                        >
                            <div className={styles.tagNameOthers}>
                                Others
                            </div>
                        </IconButton>
                    </div>


                    <div className={styles.notesInputDiv}>
                        <div className={styles.inputHeader}>
                            <div className={styles.createdBy}>
                                <div className={styles.bookmarkButton}>
                                    <IconButton
                                        size="small"
                                        onClick={() => setBookmarked(bookmarked == 0 ? 1 : 0)}
                                    >
                                        {bookmarked ? <BookmarkOutlinedIcon className={styles.pinButton}></BookmarkOutlinedIcon> : <BookmarkBorderOutlinedIcon className={styles.pinButton}></BookmarkBorderOutlinedIcon>}
                                    </IconButton>
                                </div>
                                {userName}
                            </div>
                        </div>
                        <div className={styles.selectedTags}>
                            <ColorfulTagsGen tags={tagNames} />
                        </div>
                        <div className={styles.notesTextArea}>
                            <Input
                                className={styles.notesEditor}
                                type="text"
                                placeholder="Start typing..."
                                value={newNotes}
                                multiline={true}
                                onChange={(event) => setNewNotes(event.target.value)}
                                disableUnderline="true"
                            />
                            {selectedRecordTS && <div className={styles.notesByDiv}>
                                {dayjs.utc(selectedRecordTS).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                            </div>}
                        </div>
                        <div className={styles.characterLimitDiv}>Character limit: {<div className={(newNotes.length <= 0 || newNotes.length > 1000) ? styles.characterCountRed : styles.characterCount}>{newNotes.length}</div>}/1000</div>
                    </div>
                    <div className={styles.addNotesButtons}>
                        <button className={styles.cancelButton} onClick={(event) => handleCancelAddNewNotes(event)}>Cancel</button>
                        <button className={styles.submitButton} onClick={() => submitNotes()} disabled={newNotes.length === 0 || newNotes.length > 1000}>Submit</button>
                    </div>
                </>
            )}
            <Divider style={{ marginTop: "20px" }} />

            <div className={styles.recentNotesDiv}>
                <IconButton
                    size="medium"
                    className={styles.addNotesIcon}
                /*onClick={(event) => handleAddNewNotes(event)}*/
                >
                    <SummarizeOutlinedIcon style={{ color: "#4E253A" }}></SummarizeOutlinedIcon>
                </IconButton>
                Recent Notes
            </div>
            {notes && notes.length > 0 && notes.slice(0, 3).map((notes) =>
                <>
                    <div className={styles.notesInputDiv}>
                        <div className={styles.inputHeader}>
                            <div className={styles.createdBy}>
                                <div className={styles.bookmarkButton}>
                                    <IconButton
                                        size="small"
                                        onClick={(event) => handleBookmarking(event, notes)}
                                    >
                                        {notes.isBookmarked === "true" ? (<BookmarkOutlinedIcon className={styles.pinButton}></BookmarkOutlinedIcon>) : (<BookmarkBorderOutlinedIcon className={styles.pinButton}></BookmarkBorderOutlinedIcon>)}
                                    </IconButton>
                                </div>
                                {notes.createdBy}
                            </div>
                            <div className={styles.createdDateTime}>{dayjs.utc(notes.createdDateTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div>
                        </div>
                        <div className={styles.selectedTags}>
                            <ColorfulTagsGen tags={notes.tags} />
                        </div>
                        <div className={styles.notesTextArea}>
                            <Input
                                className={expandedRecentNoteId === notes.id ? styles.notesReaderExpanded : styles.notesReader}
                                value={notes.note}
                                multiline={true}
                                readOnly={true}
                                // onKeyUp={(event) => requestSearch(event)}
                                disableUnderline="true" />
                            <div className={expandedRecentNoteId === notes.id ? styles.notesCompressedFooter : styles.notesCompressedFooterReverse}>
                                {expandedRecentNoteId === notes.id && (<>{notes.recordTimestamp ? (<div className={styles.notesByDiv}>
                                    {dayjs.utc(notes.recordTimestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                </div>) : (<div className={styles.dummyNotesByDiv}></div>)}
                                    <div className={styles.compressNotesButton}>
                                        <IconButton size="small" onClick={() => handleExpandRecentNotes(notes)}>
                                            <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
                                        </IconButton>
                                    </div></>)}

                                {expandedRecentNoteId === notes.id ? (<></>) : <div className={styles.expandNotesButton}>
                                    <IconButton size="small" onClick={() => handleExpandRecentNotes(notes)}>
                                        <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                                    </IconButton>
                                </div>}
                            </div>
                        </div>
                    </div>

                </>
            )}
            <Divider style={{ marginTop: "20px" }} />
            <div className={styles.bookmarkedNotesContainer}>
                <div className={styles.bookmarkedNotesDiv}>
                    <IconButton
                        size="medium"
                        className={styles.addNotesIcon}
                    /*onClick={(event) => handleAddNewNotes(event)}*/
                    >
                        <BookmarkBorderOutlinedIcon style={{ color: "#4E253A" }}></BookmarkBorderOutlinedIcon>
                    </IconButton>
                    Bookmarked Notes
                </div>
                {bookmarkedNotes && bookmarkedNotes.slice(0, 3).map((notes) =>
                    <>
                        <div className={styles.notesInputDiv}>
                            <div className={styles.inputHeader}>
                                <div className={styles.createdBy}>
                                    <div className={styles.bookmarkButton}>
                                        <IconButton
                                            size="small"
                                            onClick={(event) => handleBookmarking(event, notes)}
                                        >
                                            {notes.isBookmarked === "true" ? (<BookmarkOutlinedIcon className={styles.pinButton}></BookmarkOutlinedIcon>) : (<BookmarkBorderOutlinedIcon className={styles.pinButton}></BookmarkBorderOutlinedIcon>)}
                                        </IconButton>
                                    </div>
                                    {notes.createdBy}
                                </div>
                                <div className={styles.createdDateTime}>{dayjs.utc(notes.createdDateTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div>
                            </div>
                            <div className={styles.selectedTags}>
                                <ColorfulTagsGen tags={notes.tags} />
                            </div>
                            <div className={styles.notesTextArea}>
                                <Input
                                    className={expandedBookmarkedNoteId === notes.id ? styles.notesReaderExpanded : styles.notesReader}
                                    value={notes.note}
                                    multiline={true}
                                    readOnly={true}
                                    // onKeyUp={(event) => requestSearch(event)}
                                    disableUnderline="true" />
                                <div className={expandedBookmarkedNoteId === notes.id ? styles.notesCompressedFooter : styles.notesCompressedFooterReverse}>
                                    {expandedBookmarkedNoteId === notes.id && (<>{notes.recordTimestamp ? (<div className={styles.notesByDiv}>
                                        {dayjs.utc(notes.recordTimestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div>) : (<div className={styles.dummyNotesByDiv}></div>)}
                                        <div className={styles.compressNotesButton}>
                                            <IconButton size="small" onClick={() => handleExpandBookmarkedNotes(notes)}>
                                                <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
                                            </IconButton>
                                        </div></>)}

                                    {expandedBookmarkedNoteId === notes.id ? (<></>) : <div className={styles.expandNotesButton}>
                                        <IconButton size="small" onClick={() => handleExpandBookmarkedNotes(notes)}>
                                            <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                                        </IconButton>
                                    </div>}
                                </div>
                            </div>
                        </div>

                    </>
                )}
                <div className={styles.drawerFooter} onClick={(event) => handleViewAllNotes(event)}>
                    <div style={{ color: "#FFFFFF" }}>View all Notes</div>
                </div>
            </div>
        </Drawer>
    )
}