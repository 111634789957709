import React, { useContext, useEffect, useState } from "react";
import styles from "./PatientDetails.module.css";
import Grid from "@material-ui/core/Grid";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { RiRadioButtonLine } from "react-icons/ri";
import PatientDetailsTabs from "./PatientDetailsTabs/PatientDetailsTabs";
import { PatientContext } from "../../Store/PatientDataProvider";
import GetPatientInfo from "../../API/GetPatientInfo/GetPatientInfo";
import { useDispatch, useSelector } from "react-redux";
import { getAidiBaselinePayload, getAidiBaselinePayloadSuccess, getAidiBrPayload, getAidiBrPayloadSuccess, getAidiHrPayload, getAidiHrPayloadSuccess, getDiagnosesPayload, getIsLastTimerIncomplete, getIsLastTimerIncompleteSuccess, getLatestBaseline, getPatientInfo, getPatientNotes, getTimerInfo, selectedRecordIdForNotes, setIsAddBaselineMode, setIsAddBaselineModeDone, setIsAidiValidating, setIsThereNewBaselineRec, setOpenAddNotesPanel, setPatientStatus, setSelectedSubjectId, setValidationMessage } from "../../../Actions";
import Avatar from "@material-ui/core/Avatar";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import ProfileIcon from "../../../Assets/Images/ProfileIcon.png";
import { setCurrentPatientGUID, setCurrentPatientSubjectID, setPatientMainTab, setShowAidiValidationLoader, setShowLoader, setShowPatientGraphsLoader, setShowPatientProfilePageLoader, setShowPatientTimerDrawerLoader, setShowThresholdsLoader } from "../../../Actions/UtilActions";
import PatientProfile from "./PatientProfile/PatientProfile";
import { setCurrSubjectName, setCurrSubjectStatus, setSelectedMlId, setShouldAbortTimer, setShowNotesDrawer, setShowNotesPanel, setShowTimerDrawer, setShowTimerNotCompleteDialog } from "../../../Actions/DashboardActions";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@mui/material/Drawer";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { getCurSubjectStatus, getSelectedMlIdSelector, getSelectedMlVitalsTab, getSelectedSubjectId, getShowNotesDrawer, getShowNotesPanel, getShowTimerDrawer, getShowTimerNotCompleteDialog, getTimerState, getUserProfile, getUserRole } from "../../../Reducer/selectors/DashboardSelector";
import DescriptionIcon from '@mui/icons-material/Description';
import { setSelectedTimestamp } from "../../../Actions";
import Timer from "./Timer/Timer";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import NotesDrawer from "./Drawers/NotesDrawer";
import TimerDrawer from "./Drawers/TimerDrawer";
import { getIsLastTimerIncompleteSelector, getLatestMonthTimerInfoSelector } from "../../../Reducer/selectors/TimerSelector";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import Backdrop from '@mui/material/Backdrop';
import { getAllPatientsList, getCurrentPatientGUID, getCurrentPatientSubjectID, getPatientMainTab, getPatientProfilePageLoader } from "../../../Reducer/selectors/UtilSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
import { subDays } from "date-fns";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getDisplayLabelWithEhrId, getDisplayLabelWithoutEhrId } from "../../../Utils/GlobalFunctions";
import { getPatientInfoSelector } from "../../../Reducer/selectors/PatientSelector";
import logoutImg from '../../../../src/Assets/Images/Logout.png';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { globalConstants } from "../../../Constants/GlobalConstants";

export default function PatientDetails() {
  const dispatch = useDispatch();
  //console.log("PatientDetails");
  const patientDetailInfo = useSelector(getPatientInfoSelector);
  // dispatch(setSelectedMlId(patientDetailInfo.hasOwnProperty("deviceId")
  //   ? `${patientDetailInfo.deviceId}`
  //   : "None"));
  // const [patientDetailInfo, setPatientDetailInfo] = useState(patientInfoObj);
  // console.log("patientDetailInfo: ", patientDetailInfo);
  const selectedMainTab = useSelector(getPatientMainTab);
  const userProfile = useSelector(getUserProfile);
  const patientInfo = useContext(PatientContext);
  const selectedSubjectId = useSelector(getSelectedSubjectId);
  //let deviceIdContext = patientInfo.currentMouthLabId;
  //let refreshData = patientInfo.isRefresh;

  const patientList = useSelector(getAllPatientsList);
  const navigate = useNavigate();
  const timerState = useSelector(getTimerState);
  const timerIncompleteDialog = useSelector(getShowTimerNotCompleteDialog);
  const currPatientSubjectID = useSelector(getCurrentPatientSubjectID);
  const currPatientGUID = useSelector(getCurrentPatientGUID);
  const patientProfilePageLoader = useSelector(getPatientProfilePageLoader);

  const mlVitalsSelectedTab = useSelector(getSelectedMlVitalsTab);

  const curSubjectStatus = useSelector(getCurSubjectStatus);

  const selectedMlId = useSelector(getSelectedMlIdSelector);
  const role = useSelector(getUserRole);
  // const isLatestTimerIncomplete = useSelector(getIsLastTimerIncompleteSelector);
  // console.log("isLatestTimerIncomplete: ", isLatestTimerIncomplete);

  // window.addEventListener('popstate', function () {
  //   if (timerState != 0) {
  //     setShowTimerNotCompleteDialog(true);
  //   }
  // });

  // useEffect(() => {
  //   window.addEventListener('popstate', function () {
  //     if (timerState != 0) {
  //       setShowTimerNotCompleteDialog(true);
  //     }
  //   });
  // }, []);

  // window.onpopstate = () => {
  //   console.log
  //   if (timerState != 0) {
  //     setShowTimerNotCompleteDialog(true);
  //   }
  // }

  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   if (timerState != 0) {
  //     if (window.confirm("Do you want to go back ?")) {
  //       // your logic
  //       window.history.push("/");
  //     } else {
  //       window.history.pushState(null, null, window.location.pathname);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener('popstate', onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener('popstate', onBackButtonEvent);
  //   };
  // }, [window.history]);

  // useEffect(() => {
  //   let payload = {
  //     userId: userProfile.id,
  //     subjectId: currPatientSubjectID,
  //   }

  //   dispatch(getIsLastTimerIncomplete(payload));
  // }, [currPatientSubjectID]);

  function SplitTime(hours) {
    var Days = Math.floor(hours / 24);
    var Remainder = hours % 24;
    var Hours = Math.floor(Remainder);
    var Minutes = Math.floor(60 * (Remainder - Hours));
    return { Days: Days, Hours: Hours, Minutes: Minutes };
  }

  var timeResult = "--";
  parseInt(patientDetailInfo.elapsedHours) === -1 ? (timeResult = "--") : (timeResult = SplitTime(patientDetailInfo.elapsedHours));

  // Notes drawer and panel actions
  let drawerOneAction = useSelector(getShowNotesPanel);
  let drawerTwoAction = useSelector(getShowNotesDrawer);
  let timerDraweAction = useSelector(getShowTimerDrawer);

  let drawerState = {
    drawerOne: drawerOneAction,
    drawerTwo: drawerTwoAction,
    timerDrawer: timerDraweAction,
  };

  const useStyles = makeStyles((theme) => ({
    patientDetailsDiv: {
      marginRight: drawerState => (drawerState.drawerTwo || drawerState.timerDrawer)
        ? '350px' :
        drawerState.drawerOne ? '50px' : '0px',

      overflowX: "hidden",

      transition: drawerState => !drawerState.drawerOne
        ? theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: "500ms",
        })
        : theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: "500ms",
        }),
    },

    primaryDrawer: {
      width: "50px",
      zIndex: 5,
      flexShrink: 0,
      '& .MuiDrawer-root': drawerState => (drawerState.drawerTwo || drawerState.timerDrawer)
        ? {
          position: 'absolute',
          marginRight: "300px",
        } : {
          position: 'relative',
          marginRight: "0px",
        },
      "& .MuiDrawer-paper": {
        backgroundColor: "#FCF9FA"
      },
      transition: drawerState => !(drawerState.drawerTwo || drawerState.timerDrawer)
        ? theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: "500ms",
        })
        : theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: "500ms",
        }),
    },

    primaryDrawerPaper: {
      width: "50px",
      boxSizing: "border-box",
      zIndex: 5,
      position: 'absolute',
      marginRight: drawerState => (drawerState.drawerTwo || drawerState.timerDrawer)
        ? "300px" : "0px",
      transition: drawerState => !(drawerState.drawerTwo || drawerState.timerDrawer)
        ? theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: "500ms",
        })
        : theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: "500ms",
        }),
    },

    secondaryDrawer: {
      maximumWidth: "300px",
      zIndex: 3,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        backgroundColor: "#F3E8EE"
      },
      transition: drawerState => !(drawerState.drawerTwo || drawerState.timerDrawer)
        ? theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: "500ms",
        })
        : theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: "500ms",
        }),
    },

    secondaryDrawerPaper: {
      width: "300px",
      boxSizing: "border-box",
      zIndex: 3,
      transition: drawerState => !(drawerState.drawerTwo || drawerState.timerDrawer)
        ? theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: "500ms",
        })
        : theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: "500ms",
        }),
    }

  }));

  const classes = useStyles(drawerState);

  const handleCloseDrawer = (event) => {
    event.preventDefault();
    dispatch(setShowNotesPanel(!drawerState.drawerOne));
    dispatch(selectedRecordIdForNotes(''));
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setOpenAddNotesPanel(false));
    dispatch(setSelectedTimestamp(""));
  };

  const handleNotesDrawerOpen = (event) => {
    event.preventDefault();
    dispatch(setShowNotesPanel(true));
    dispatch(setShowTimerDrawer(false));
    dispatch(selectedRecordIdForNotes(''));
    dispatch(setShowNotesDrawer(!drawerState.drawerTwo));

    if (!drawerState.drawerTwo) {
      dispatch(setOpenAddNotesPanel(false));
      const payload = {
        subjectId: selectedSubjectId.selectedSubjectId,
        recordId: ''
      }
      dispatch(getPatientNotes(payload));
    }
  };

  const latestMonthTimerInfo = useSelector(getLatestMonthTimerInfoSelector);
  const handleTimerDrawerOpen = (event) => {
    event.preventDefault();
    dispatch(setShowNotesPanel(true));
    dispatch(setShowNotesDrawer(false));
    dispatch(selectedRecordIdForNotes(''));
    dispatch(setShowTimerDrawer(!drawerState.timerDrawer));

    if (!drawerState.timerDrawer) {

      const timerPayload = {
        userId: userProfile.id,
        subjectGuid: currPatientGUID,
      }
      dispatch(getTimerInfo(timerPayload));

      dispatch(setOpenAddNotesPanel(false));
      const payload = {
        subjectId: selectedSubjectId.selectedSubjectId,
        recordId: ''
      }
      dispatch(getPatientNotes(payload));
    }
  };

  const onChangeMainTab = (newValue) => {
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    dispatch(setPatientMainTab(newValue));
  };


  const resetAidiStates = () => {
    dispatch(getAidiBaselinePayloadSuccess([]));
    dispatch(getAidiBrPayloadSuccess([]));
    dispatch(getAidiHrPayloadSuccess([]));
    dispatch(setIsAddBaselineMode(false));
    dispatch(setIsAddBaselineModeDone(false));
    dispatch(setIsAidiValidating(false));
    dispatch(setIsThereNewBaselineRec(false));
    dispatch(setValidationMessage(""));
    dispatch(setShowAidiValidationLoader(false));
  };

  const handlePrevPatient = (event) => {
    event.preventDefault();
    resetAidiStates();
    let prevPatientGUID = patientList && patientList[0].guid;
    let prevPatientID = patientList && patientList[0].subjectId;
    let prevPatientMLID = patientList && patientList[0].deviceId;

    if (currPatientGUID === prevPatientGUID) {
      return;
    }

    for (const x of patientList) {

      if (x.guid === currPatientGUID) {
        //console.log("PREV PATIENT: ", patientList && patientList[0]);
        dispatch(setShowLoader(true));
        dispatch(setCurrentPatientGUID(prevPatientGUID));
        dispatch(setCurrentPatientSubjectID(prevPatientID));
        console.log('setSelectedMlId');
        dispatch(setSelectedMlId(prevPatientMLID));
        patientInfo.setSubjectGUID(prevPatientGUID);
        patientInfo.setSubjectId(prevPatientID);
        patientInfo.setCurrentMouthLabId(prevPatientMLID);
        dispatch(setShowPatientProfilePageLoader(true));
        dispatch(setShowPatientTimerDrawerLoader(true));
        dispatch(setShowPatientGraphsLoader(true));
        let payload = {
          subjectId: prevPatientID,
          startDate: (subDays(new Date(), 120)).toISOString().split('T')[0],
          endDate: (new Date()).toISOString().split('T')[0],
        };
        dispatch(getDiagnosesPayload({ subjectId: prevPatientID }));
        dispatch(getAidiBrPayload(payload));
        dispatch(getAidiHrPayload(payload));
        dispatch(getAidiBaselinePayload(payload));
        navigate(`/patientDetails/${prevPatientGUID}`);
        dispatch(setShowThresholdsLoader(true));
        dispatch(getLatestBaseline({ subjectGuid: prevPatientGUID }));
      } else {
        prevPatientGUID = x.guid;
        prevPatientID = x.subjectId;
        prevPatientMLID = x.deviceId;
      }
    }
    dispatch(setOpenAddNotesPanel(false));
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    dispatch(setPatientMainTab(0));
  };


  const handleNextPatient = (event) => {
    event.preventDefault();
    resetAidiStates();
    const listLen = patientList.length;

    let count = 0;
    for (count = 0; count < listLen; count++) {
      if (patientList[count].guid && currPatientGUID === patientList[count].guid) {
        break;
      }
    };

    if (count != listLen - 1) {
      dispatch(setShowLoader(true));
      dispatch(setCurrentPatientGUID(patientList[count + 1].guid));
      dispatch(setCurrentPatientSubjectID(patientList[count + 1].subjectId));
      dispatch(setSelectedMlId(patientList[count + 1].deviceId));
      patientInfo.setSubjectGUID(patientList[count + 1].guid);
      patientInfo.setSubjectId(patientList[count + 1].subjectId);
      patientInfo.setCurrentMouthLabId(patientList[count + 1].deviceId);
      dispatch(setShowPatientProfilePageLoader(true));
      dispatch(setShowPatientTimerDrawerLoader(true));
      dispatch(setShowPatientGraphsLoader(true));
      let payload = {
        subjectId: patientList[count + 1].subjectId,
        startDate: (subDays(new Date(), 120)).toISOString().split('T')[0],
        endDate: (new Date()).toISOString().split('T')[0],
      };
      dispatch(getDiagnosesPayload({ subjectId: patientList[count + 1].subjectId }));
      dispatch(getAidiBrPayload(payload));
      dispatch(getAidiHrPayload(payload));
      dispatch(getAidiBaselinePayload(payload));
      navigate(`/patientDetails/${patientList[count + 1].guid}`);
      dispatch(setShowNotesDrawer(false));
      dispatch(setShowTimerDrawer(false));
    }
    dispatch(setOpenAddNotesPanel(false));
    dispatch(setShowNotesDrawer(false));
    dispatch(setShowTimerDrawer(false));
    dispatch(setShowNotesPanel(false));
    dispatch(setPatientMainTab(0));
    dispatch(setShowThresholdsLoader(true));
    dispatch(getLatestBaseline({ subjectGuid: patientList[count + 1].guid }));
  };

  useEffect(() => {
    // GetPatientInfo(currPatientGUID).then((res) => {
    //   dispatch(setCurrSubjectStatus(res.status));
    //   setPatientDetailInfo(res);
    //   dispatch(setSelectedSubjectId(res.subjectId));
    //   dispatch(setSelectedMlId(res.hasOwnProperty("device")
    //     ? res.device.id
    //     : res.hasOwnProperty("deviceId")
    //       ? res.deviceId
    //       : "None"));
    //   dispatch(setShowPatientProfilePageLoader(false));
    // });
    dispatch(setShowPatientProfilePageLoader(true));
    dispatch(getPatientInfo(currPatientGUID));
    //setPatientDetailInfo(patientInfoObj);
  }, [currPatientGUID]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  function closeTimerNotCompleteDialog(event, reason) {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // Set 'open' to false, however you would do that with your particular code.
      dispatch(setShowTimerNotCompleteDialog(false));
    }
  };

  function closeIncompleteTimerWarning(event, reason) {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // Set 'open' to false, however you would do that with your particular code.
      dispatch(getIsLastTimerIncompleteSuccess(false));
    }
  };

  function abortTimer() {
    dispatch(setShouldAbortTimer(true));
    dispatch(setShowTimerNotCompleteDialog(false));
  }

  const getPatientLabel = (patientDetailInfo) => {
    let displayName = "";
    if (patientDetailInfo.contactInfo.firstName != "") {
      // if (patientDetailInfo.ehrId != null && patientDetailInfo.ehrId != "") {
      //   return getDisplayLabelWithEhrId(patientDetailInfo.firstName, patientDetailInfo.lastName, patientDetailInfo.ehrId);
      // }
      let fullName = getDisplayLabelWithoutEhrId(patientDetailInfo.contactInfo.firstName, patientDetailInfo.contactInfo.lastName);
      if (fullName.length > 15) {
        // Trim the combined string to 15 characters and add ",.."
        //dispatch(setCurrSubjectName(fullName.substring(0, 15) + "...")); 
        return fullName.substring(0, 15) + "...";
      } else {
        // Use the full combined name
        //dispatch(setCurrSubjectName(fullName)); 
        return fullName;
      }
    }
    const subjectlabel = "ADR - " + patientDetailInfo.subjectId;
    return subjectlabel;
  };

  const updatePatientStatus = (newStatus, event, popupState) => {
    event.preventDefault();
    const payload = {
      patientGuid: currPatientGUID,
      status: newStatus,
    };

    dispatch(setPatientStatus(payload));
  };

  const RenderStatuses = (props) => {
    return (
      <FormGroup sx={{ marginTop: '10px' }}>
        <FormControlLabel className={curSubjectStatus === 'Active' ? styles.filtersDivTextSelectedActive : styles.filtersDivTextActive} control={<Radio size="small" sx={{ color: "#1EF793", '&.Mui-checked': { color: "#1EF793" } }} name={'Active'} checked={true} onClick={(event) => updatePatientStatus('Active', event, props.popupState)} />} label={'Active'} />
        <FormControlLabel className={curSubjectStatus === 'Registered' ? styles.filtersDivTextSelectedRegistered : styles.filtersDivTextActive} control={<Radio size="small" sx={{ color: "#A040AB", '&.Mui-checked': { color: "#A040AB" } }} name={'Registered'} checked={true} onClick={(event) => updatePatientStatus('Registered', event, props.popupState)} />} label={'Registered'} />
        <FormControlLabel className={curSubjectStatus === 'Terminated' ? styles.filtersDivTextSelectedTerminated : styles.filtersDivTextActive} control={<Radio size="small" sx={{ color: "#999999", '&.Mui-checked': { color: "#999999" } }} name={'Terminated'} checked={true} onClick={(event) => updatePatientStatus('Terminated', event, props.popupState)} />} label={'Terminated'} />
        <FormControlLabel className={curSubjectStatus === 'Calibrate' ? styles.filtersDivTextSelectedCalibrate : styles.filtersDivTextActive} control={<Radio size="small" sx={{ color: "#FF3C3C", '&.Mui-checked': { color: "#FF3C3C" } }} name={'Calibrate'} checked={true} onClick={(event) => updatePatientStatus('Calibrate', event, props.popupState)} />} label={'Calibrate'} />
        <FormControlLabel className={curSubjectStatus === 'Completed' ? styles.filtersDivTextSelectedCompleted : styles.filtersDivTextActive} control={<Radio size="small" sx={{ color: "#35AAF7", '&.Mui-checked': { color: "#35AAF7" } }} name={'Completed'} checked={true} onClick={(event) => updatePatientStatus('Completed', event, props.popupState)} />} label={'Completed'} />
      </FormGroup>
    );
  };

  const StatusPanel = (props) => {
    return (
      <div className={styles.wrapper}>
        <RenderStatuses popupState={props.popupState}></RenderStatuses>
      </div>
    );
  }

  if (patientProfilePageLoader) {
    return (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>);
  } else {
    return (
      <div className={styles.rootDiv}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={timerIncompleteDialog}
        ></Backdrop>
        <div>
          <div className={classes.patientDetailsDiv}>
            <Grid container style={{ overflow: "hidden" }}>
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.topDiv}>
                  <div className={styles.timerDiv}>
                    {(role !== globalConstants.USER_ROLE_CARE_MANAGER) && (<Timer patientDetailInfo={patientDetailInfo}></Timer>)}
                  </div>
                  <div className={styles.topBarButtons}>

                    <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                      {(popupState) => (
                        <div className={curSubjectStatus === 'Active' ? styles.activeStatusDiv : curSubjectStatus === 'Registered' ? styles.registeredStatusDiv : curSubjectStatus === 'Terminated' ? styles.terminatedStatusDiv : curSubjectStatus === 'Calibrate' ? styles.calibrateStatusDiv : styles.completedStatusDiv} {...bindToggle(popupState)}>
                          <Radio size="small" sx={{ color: curSubjectStatus === 'Active' ? "#1EF793" : curSubjectStatus === 'Registered' ? "#A040AB" : curSubjectStatus === 'Terminated' ? "#999999" : curSubjectStatus === 'Calibrate' ? "#FF3C3C" : "#35AAF7", '&.Mui-checked': { color: curSubjectStatus === 'Active' ? "#1EF793" : curSubjectStatus === 'Registered' ? "#A040AB" : curSubjectStatus === 'Terminated' ? "#999999" : curSubjectStatus === 'Calibrate' ? "#FF3C3C" : "#35AAF7" } }} checked={true}></Radio>
                          <div className={styles.filterDivLabel} >{curSubjectStatus}</div>
                          <div>
                            <ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>
                          </div>
                          <Popper {...bindPopper(popupState)} transition>
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={350}>
                                <Paper className={styles.filterBackdrop}>
                                  <StatusPanel popupState={popupState} />
                                </Paper>
                              </Fade>
                            )}
                          </Popper>
                        </div>
                      )}
                    </PopupState>

                    <div className={styles.mlIdDiv}>
                      <div className={styles.mlIdDiv1}>ML ID</div>
                      <div className={styles.mlIdDiv2}>
                        {selectedMlId}
                      </div>
                    </div>

                    {/* <div className={styles.adherence}>
                      <div>Adherence :&nbsp;</div>
                      {patientDetailInfo.hasOwnProperty("adherence")
                        ? `${patientDetailInfo.adherence}`
                        : "None"}
                    </div> */}
                    <div className={styles.subjectId}>
                      {/* Replace AIDI from site context  */}
                      <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                        {(popupState) => (
                          <div className={styles.patientIdDiv}>
                            <Avatar className={styles.avatar} alt="avatar_patient" {...bindToggle(popupState)} src={ProfileIcon}></Avatar>
                            <div>{patientDetailInfo.hasOwnProperty("firstname")}
                              {getPatientLabel(patientDetailInfo)}
                            </div>
                            <div className={styles.subjectIdButtons}>
                              <HtmlTooltip
                                title={"Previous Profile"}
                              >
                                <IconButton
                                  size="medium"
                                  className={styles.iconButtonWrapper}>
                                  <IconButton
                                    size="medium"
                                    className={styles.iconButtonLeft}
                                    onClick={(event) => handlePrevPatient(event)}
                                    disabled={(timerState != 0)}>
                                    <KeyboardArrowLeftIcon style={{ color: `#FFFFFF` }}></KeyboardArrowLeftIcon>
                                  </IconButton>
                                </IconButton>
                              </HtmlTooltip>
                              <HtmlTooltip
                                title={"Next Profile"}
                              >
                                <IconButton
                                  size="medium"
                                  className={styles.iconButtonWrapper}>
                                  <IconButton
                                    size="medium"
                                    className={styles.iconButtonRight}
                                    onClick={(event) => handleNextPatient(event)}
                                    disabled={(timerState != 0)}>
                                    <KeyboardArrowRightIcon style={{ color: `#FFFFFF` }}></KeyboardArrowRightIcon>
                                  </IconButton>
                                </IconButton>
                              </HtmlTooltip>
                            </div>
                            <Popper {...bindPopper(popupState)} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper sx={{ p: 2, marginTop: 1, marginLeft: 30, marginRight: 1 }}>
                                    <PatientProfile patientDetailInfo={patientDetailInfo} popupState={popupState} elapsedHours={timeResult} />
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>
                    </div>
                  </div>
                </div>
              </Grid>

              {/* Contents of the page - Patient Tabs  */}
              <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.tabsContainer}>
                  {/* <PatientDetailsTabs patientProfile={patientProfile} /> */}
                  <PatientDetailsTabs
                    selectedMainTab={selectedMainTab}
                    onChangeMainTab={onChangeMainTab} />
                </div>
              </Grid>
            </Grid>
          </div >
          {(!drawerState.drawerOne && mlVitalsSelectedTab.selectedMlVitalsTab != 1) && <div className={styles.notesDrawerButton}>
            <IconButton
              size="large"
              className={styles.iconNotesDrawerButton}
              onClick={(event) => handleCloseDrawer(event)}>
              <KeyboardArrowLeftIcon style={{ color: `#4E253A` }}></KeyboardArrowLeftIcon>
            </IconButton>
          </div>
          }
          <Drawer
            className={classes.primaryDrawer}
            variant="persistent"
            anchor="right"
            open={drawerState.drawerOne}
            classes={{
              paper: classes.primaryDrawerPaper,
            }}
          >
            <div className={styles.notesButton}>
              <IconButton
                size="large"
                className={styles.iconNotesButton}
                onClick={(event) => handleNotesDrawerOpen(event)}>
                {drawerState.drawerTwo ? (<DescriptionIcon style={{ color: `#4E253A` }}></DescriptionIcon>) : (<DescriptionOutlinedIcon style={{ color: `#4E253A` }}></DescriptionOutlinedIcon>)}
              </IconButton>
            </div>

            <div className={styles.timerButton}>
              <IconButton
                size="large"
                className={styles.iconNotesButton}
                onClick={(event) => handleTimerDrawerOpen(event)}>
                {drawerState.drawerTwo ? (<AccessAlarmsIcon style={{ color: `#4E253A` }}></AccessAlarmsIcon>) : (<AccessAlarmsOutlinedIcon sx={{ color: `#4E253A` }}></AccessAlarmsOutlinedIcon>)}
              </IconButton>
            </div>

            <div className={styles.notesDrawerButtonInside}>
              <IconButton
                size="large"
                className={styles.iconNotesDrawerButton}
                onClick={(event) => handleCloseDrawer(event)}>
                <KeyboardArrowRightIcon style={{ color: `#4E253A` }}></KeyboardArrowRightIcon>
              </IconButton>
            </div>
          </Drawer>
        </div >

        <NotesDrawer></NotesDrawer>

        <TimerDrawer latestMonthTimerInfo={latestMonthTimerInfo} />

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={styles.modal}
          open={timerIncompleteDialog}
          onClose={(event, reason) => closeTimerNotCompleteDialog(event, reason)}
          hideBackdrop={true}
        >

          <div className={styles.paper}>
            <div className={styles.menuTitle}>Warning</div>

            <div className={styles.patientListDiv}>
              <Grid container>
                <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.userQuestionMessage}>
                    This action will end your session. What would you like to do?</div>

                  <div className={styles.mouthLabImgDiv}>
                    <img src={logoutImg} loading="lazy" className={styles.mlImg} />
                  </div>

                  <div className={styles.buttonContainer}>
                    <button type="button" onClick={() => abortTimer()} className={styles.cancelButton}>
                      Abort
                    </button>
                    <button type="submit" onClick={closeTimerNotCompleteDialog} className={styles.submitButton}>
                      Continue
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

        </Modal>

        {/* <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={styles.modal}
          open={isLatestTimerIncomplete}
          onClose={(event, reason) => closeIncompleteTimerWarning(event, reason)}
          hideBackdrop={true}
        >
          <div className={styles.paperUnassignML}>
            <div className={styles.menuTitle}>
              <div className={styles.titleText}>Warning</div>
              <div>
                <IconButton aria-label="delete">
                  <RiCloseCircleFill size={29} onClick={closeIncompleteTimerWarning} style={{ color: "#4E253A" }} />
                </IconButton>
              </div>
            </div>

            <div className={styles.patientListDiv}>
              The last session was incomplete. Please record your session manually.
            </div>

            <div className={styles.menuButtons2}>
              <div className={styles.continueButton} onClick={closeIncompleteTimerWarning}>
                Ok
              </div>
            </div>
          </div>
        </Modal> */}

      </div >
    );
  }
}
