
import styles from "./DiagnosesTable.module.css";
import Grid from "@material-ui/core/Grid";
import { FaSort } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getDiagnosesLoader } from "../../../../Reducer/selectors/UtilSelector";
import { getPatientDiagnoses } from "../../../../Reducer/selectors/PatientSelector";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

export default function DiagnosesTable(props) {

    const patientDiagnosesLoader = useSelector(getDiagnosesLoader);
    const [diagnosesListSorting, setDiagnosesListSorting] = useState([]);
    const [sortDirection, setSortDirection] = useState(false);
    const patientDiagnoses = useSelector(getPatientDiagnoses);
    console.log("patientDiagnoses: ", patientDiagnoses);

    useEffect(() => {
        setDiagnosesListSorting(patientDiagnoses);
    }, [patientDiagnoses]);

    const diagnosesTableBody = diagnosesListSorting.map((diag) => {
        return (
            <div>
                <table className={styles.responsive1}>
                    <tbody>
                        <tr>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsCode}>{diag.icdCode}</div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContents}>{diag.type}</div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsDesc}>{diag.description.length > 100 ? diag.description.substring(0, 40) + "..." : diag.description}</div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsCD}>{diag.rank}</div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsCD}>{diag.classification}</div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsCD}>{diag.createdDate}</div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsCD}>{diag.createdBy}</div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsNotes}><DescriptionOutlinedIcon sx={{ color: "#C6C8C8" }} /></div></td>
                            <td className={styles.tableCellContainer}><div className={styles.tableCellContentsNotes}><BorderColorOutlinedIcon sx={{ color: "#C6C8C8" }} /></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>)
    });



    const handleStringSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...diagnosesListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key]?.localeCompare(b[key]);
            } else {
                return b[key]?.localeCompare(a[key]);
            }
        });
        return setDiagnosesListSorting(sortableItem);
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (


        <Grid container>
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
                <table className={styles.responsive}>
                    <thead>
                        <tr>
                            <th className={styles.codeHeader} onClick={() => handleStringSorting("icdCode")}>
                                <div className={styles.headingContainer1}>
                                    Code
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.typeHeader} onClick={() => handleStringSorting("type")}>
                                <div className={styles.headingContainer1}>
                                    Type
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.descHeader} onClick={() => handleStringSorting("description")}>
                                <div className={styles.headingContainer1}>
                                    Description
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.classHeader} onClick={() => handleStringSorting("rank")}>
                                <div className={styles.headingContainer1}>
                                    Rank
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.cdHeader} onClick={() => handleStringSorting("classification")}>
                                <div className={styles.headingContainer1}>
                                    Classification
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.cdHeader}>
                                <div className={styles.headingContainer1}>
                                    Created Date
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                YYYY-MM-DD
                                            </React.Fragment>
                                        }
                                    >
                                        <InfoOutlinedIcon className={styles.iconsStyleSort1} />
                                    </HtmlTooltip>
                                </div>
                            </th>
                            <th className={styles.classHeader} onClick={() => handleStringSorting("createdBy")}>
                                <div className={styles.headingContainer}>
                                    Created By
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.typeHeader}>
                                <div className={styles.headingContainer}>
                                    Notes
                                </div>
                            </th>
                            <th className={styles.typeHeader}>
                                <div className={styles.headingContainer}>
                                    Update
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className={styles.patientListContainer} style={{ maxHeight: '60vh', overflowY: 'scroll', maxWidth: '100vw' }}>
                    {patientDiagnosesLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : diagnosesTableBody}
                </div>
            </Grid>
            {/*<div className={styles.bottomNavDiv}>
                        <div className={styles.navButttonDiv}>
                            <button disabled={pccPageNumber === 1} className={pccPageNumber === 1 ? styles.navButtonsDisable : styles.navButtons} onClick={() => goToPrevPage()}>
                                Previous
                            </button>
                        </div>
                        <div className={styles.pageSizeNumberDiv}>
                            <div className={styles.pageNumberDiv}>
                                <InputLabel style={{ fontSize: "12px", fontFamily: "GTWalsheimProRegular" }}>Page Size:</InputLabel>
                            </div>
                            <div className={styles.pageSizeDiv}>
                                <Select
                                    value={pccPageSize}
                                    onChange={(event) => handleSelectPageSize(event)}
                                >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                    <MenuItem value="200">200</MenuItem>
                                </Select>
                            </div>
                            <div className={styles.pageNumberDiv}>
                                <InputLabel style={{ fontSize: "12px", fontFamily: "GTWalsheimProRegular" }}>Page:</InputLabel>
                            </div>
                            <div className={styles.pageNumberDiv}>
                                {pccPageNumber}
                            </div>
                        </div>
                        <div className={styles.navButttonDiv}>
                            <button disabled={props.paging && !props.paging.hasMore} className={props.paging && !props.paging.hasMore ? styles.navButtonsDisable : styles.navButtons} onClick={() => goToNextPage()}>
                                Next
                            </button>
                        </div>
                                    </div>*/}
        </Grid>

    )

}