import styles from "./PatientCareteam.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { setPRCareTeamInfo, setRegistrationStatus } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import CloseIcon from '@mui/icons-material/Close';
import { getCareteamFormEdit1, getCareteamFormEdit2, getCareteamFormEdit3, getPatientInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import { setCareteamFormEdit1, setCareteamFormEdit2, setCareteamFormEdit3 } from "../../../../Actions";

const PatientCareteam = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const [contactsCount, setContactsCount] = useState(1);

    const [showSecContact, setShowSecContact] = useState(false);
    const [showTerContact, setShowTerContact] = useState(false);

    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const patientInfoObj = useSelector(getPatientInfoSelector);
    //console.log('patientInfoObj: ', patientInfoObj);

    const [prefixC1, setPrefixC1] = useState([{ value: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.prefix : '', label: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.prefix : '' }]);
    const [firstNameC1, setFirstNameC1] = useState(patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.firstName : '');
    const [suffixC1, setSuffixC1] = useState([{ value: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.suffix : '', label: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.suffix : '' }]);
    const [lastNameC1, setLastNameC1] = useState(patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.lastName : '');
    const [specC1, setSpecC1] = useState(patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].speciality : '');
    const [profC1, setProfC1] = useState([{ value: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].profession : '', label: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].profession : '' }]);
    const [npiC1, setNpiC1] = useState(patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.npi : '');
    const [emailC1, setEmailC1] = useState(patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.email : '');
    const [phNumC1, setPhNumC1] = useState(patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo.phone : '');
    const [comModeC1, setComModeC1] = useState([{ value: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo ? patientInfoObj.caregivers[0].contactInfo.prefCommMode : '' : '', label: patientInfoObj.caregivers[0] ? patientInfoObj.caregivers[0].contactInfo ? patientInfoObj.caregivers[0].contactInfo.prefCommMode : '' : '' }]);

    const [prefixC2, setPrefixC2] = useState([{ value: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.prefix : '', label: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.prefix : '' }]);
    const [firstNameC2, setFirstNameC2] = useState(patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.firstName : '');
    const [suffixC2, setSuffixC2] = useState([{ value: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.suffix : '', label: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.suffix : '' }]);
    const [lastNameC2, setLastNameC2] = useState(patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.lastName : '');
    const [specC2, setSpecC2] = useState(patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].speciality : '');
    const [profC2, setProfC2] = useState([{ value: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].profession : '', label: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].profession : '' }]);
    const [npiC2, setNpiC2] = useState(patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.npi : '');
    const [emailC2, setEmailC2] = useState(patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.email : '');
    const [phNumC2, setPhNumC2] = useState(patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo.phone : '');
    const [comModeC2, setComModeC2] = useState([{ value: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo ? patientInfoObj.caregivers[1].contactInfo.prefCommMode : '' : '', label: patientInfoObj.caregivers[1] ? patientInfoObj.caregivers[1].contactInfo ? patientInfoObj.caregivers[1].contactInfo.prefCommMode : '' : '' }]);

    const [prefixC3, setPrefixC3] = useState([{ value: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.prefix : '', label: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.prefix : '' }]);
    const [firstNameC3, setFirstNameC3] = useState(patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.firstName : '');
    const [suffixC3, setSuffixC3] = useState([{ value: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.suffix : '', label: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.suffix : '' }]);
    const [lastNameC3, setLastNameC3] = useState(patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.lastName : '');
    const [specC3, setSpecC3] = useState(patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].speciality : '');
    const [profC3, setProfC3] = useState([{ value: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].profession ? patientInfoObj.caregivers[2].profession : '' : '', label: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].profession ? patientInfoObj.caregivers[2].profession : '' : '' }]);
    const [npiC3, setNpiC3] = useState(patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.npi : '');
    const [emailC3, setEmailC3] = useState(patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.email : '');
    const [phNumC3, setPhNumC3] = useState(patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo.phone : '');
    const [comModeC3, setComModeC3] = useState([{ value: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo ? patientInfoObj.caregivers[2].contactInfo.prefCommMode : '' : '', label: patientInfoObj.caregivers[2] ? patientInfoObj.caregivers[2].contactInfo ? patientInfoObj.caregivers[2].contactInfo.prefCommMode : '' : '' }]);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isFirstNameC1Fill, setIsFirstNameC1Fill] = useState(true);
    const [isLastNameC1Fill, setIsLastNameC1Fill] = useState(true);
    const [isProfC1Fill, setIsProfC1Fill] = useState(true);
    const [isEmailC1Fill, setIsEmailC1Fill] = useState(true);
    const [isPhNumC1Fill, setIsPhNumC1Fill] = useState(true);
    const [isComModeC1Fill, setIsComModeC1Fill] = useState(true);

    const [isFirstNameC2Fill, setIsFirstNameC2Fill] = useState(true);
    const [isLastNameC2Fill, setIsLastNameC2Fill] = useState(true);
    const [isProfC2Fill, setIsProfC2Fill] = useState(true);
    const [isEmailC2Fill, setIsEmailC2Fill] = useState(true);
    const [isPhNumC2Fill, setIsPhNumC2Fill] = useState(true);
    const [isComModeC2Fill, setIsComModeC2Fill] = useState(true);

    const [isFirstNameC3Fill, setIsFirstNameC3Fill] = useState(true);
    const [isLastNameC3Fill, setIsLastNameC3Fill] = useState(true);
    const [isProfC3Fill, setIsProfC3Fill] = useState(true);
    const [isEmailC3Fill, setIsEmailC3Fill] = useState(true);
    const [isPhNumC3Fill, setIsPhNumC3Fill] = useState(true);
    const [isComModeC3Fill, setIsComModeC3Fill] = useState(true);

    const isCareteamFormEditable1 = useSelector(getCareteamFormEdit1);
    const isCareteamFormEditable2 = useSelector(getCareteamFormEdit2);
    const isCareteamFormEditable3 = useSelector(getCareteamFormEdit3);

    useEffect(() => {
        if (patientInfoObj.caregivers[1] && patientInfoObj.caregivers[1].contactInfo.firstName !== '') {
            setShowSecContact(true);
        }
        if (patientInfoObj.caregivers[2] && patientInfoObj.caregivers[2].contactInfo.firstName !== '') {
            setShowTerContact(true);
        }
    }, [patientInfoObj]);

    const clearC1Fields = () => {
        setPrefixC1([]);
        setFirstNameC1('');
        setSuffixC1([]);
        setLastNameC1('');
        setSpecC1('');
        setProfC1([]);
        setNpiC1('');
        setEmailC1('');
        setPhNumC1('');
        setComModeC1([]);
    };

    const clearC2Fields = () => {
        setPrefixC2([]);
        setFirstNameC2('');
        setSuffixC2([]);
        setLastNameC2('');
        setSpecC2('');
        setProfC2([]);
        setNpiC2('');
        setEmailC2('');
        setPhNumC2('');
        setComModeC2([]);
    };

    const clearC3Fields = () => {
        setPrefixC3([]);
        setFirstNameC3('');
        setSuffixC3([]);
        setLastNameC3('');
        setSpecC3('');
        setProfC3([]);
        setNpiC3('');
        setEmailC3('');
        setPhNumC3('');
        setComModeC3([]);
    };

    const clearAllCareTeamFields = () => {
        clearC1Fields();
        clearC2Fields();
        clearC3Fields();
        resetReqFills();
    };

    const resetC1ReqFills = () => {
        setIsFirstNameC1Fill(true);
        setIsLastNameC1Fill(true);
        setIsProfC1Fill(true);
        setIsEmailC1Fill(true);
        setIsPhNumC1Fill(true);
        setIsComModeC1Fill(true);
    };

    const resetC2ReqFills = () => {
        setIsFirstNameC2Fill(true);
        setIsLastNameC2Fill(true);
        setIsProfC2Fill(true);
        setIsEmailC2Fill(true);
        setIsPhNumC2Fill(true);
        setIsComModeC2Fill(true);
    };

    const resetC3ReqFills = () => {
        setIsFirstNameC3Fill(true);
        setIsLastNameC3Fill(true);
        setIsProfC3Fill(true);
        setIsEmailC3Fill(true);
        setIsPhNumC3Fill(true);
        setIsComModeC3Fill(true);
    };

    const resetReqFills = () => {
        resetC1ReqFills();
        resetC2ReqFills();
        resetC3ReqFills();
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllCareTeamFields: clearAllCareTeamFields,
        };
    });

    const optionsPrefix = [
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Miss.', label: 'Miss.' },
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
    ];

    const optionsSuffix = [
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'Jr.', label: 'Jr.' },
        { value: 'Sr.', label: 'Sr.' },
    ];

    const optionsProf = [
        { value: 'Alternate Physician', label: 'Alternate Physician' },
        { value: 'Attending Physician', label: 'Attending Physician' },
        { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
        { value: 'Physician Assistant', label: 'Physician Assistant' },
        { value: 'Registered Nurse', label: 'Registered Nurse' },
    ];

    const optionsComMode = [
        { value: 'Email', label: 'Email' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Both', label: 'Both' },
    ];

    const customContentRendererMulti = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            return (
                <div>
                    Selected <b>{state.values.length}</b> out of{" "}
                    <b>{props.options.length}</b>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    };

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label && state.values[0].label.length > 20) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 20)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const onAddNewContact = () => {
        if (contactsCount < 3) {
            setContactsCount(contactsCount + 1);
        } else {
            setContactsCount(1);
        }
    };

    const onAddSecContact = (event) => {
        event.preventDefault();
        if (firstNameC1 !== '') {
            setShowSecContact(true);
            setContactsCount(2);
        }
    };

    const onAddTerContact = (event) => {
        event.preventDefault();
        if (firstNameC2 !== '') {
            setShowTerContact(true);
            setContactsCount(3);
        }
    };

    const deleteContact = () => {
        if (contactsCount === 2) {
            clearC2Fields();
            resetC2ReqFills();
            setContactsCount(1);
        } else if (contactsCount === 3) {
            clearC3Fields();
            resetC3ReqFills();
            setContactsCount(2);
        } else {
            let temp = contactsCount - 1;
            setContactsCount(temp);
        }
    };

    const deleteSecContact = (event) => {
        event.preventDefault();
        setShowSecContact(false);
        clearC2Fields();
        resetC2ReqFills();
        setContactsCount(1);
    };

    const deleteTerContact = (event) => {
        event.preventDefault();
        setShowTerContact(false);
        clearC3Fields();
        resetC3ReqFills();
        setContactsCount(2);
    };


    const getStringFromSelectArray = (arr) => {
        const len = arr.length;
        let res = "";
        for (let i = 0; i < len; i++) {
            if (res.length > 0) {
                res = res + ", " + arr[i].value;
            } else {
                res = res + arr[i].value;
            }
        }
        return res;
    };

    const checkIfContact1IsDone = () => {
        if ((firstNameC1 !== '') && (lastNameC1 !== '') && (profC1[0] && profC1[0].value !== '') && (emailC1 !== '') && (phNumC1 !== '') && (comModeC1[0] && comModeC1[0].value !== '')) {
            resetC1ReqFills();
            return true;
        } else {
            resetC1ReqFills();
            if (firstNameC1 === '') {
                setIsFirstNameC1Fill(false);
            };
            if (lastNameC1 === '') {
                setIsLastNameC1Fill(false);
            };
            if (!profC1[0] || profC1[0].value === '') {
                setIsProfC1Fill(false);
            };
            if (emailC1 === '') {
                setIsEmailC1Fill(false);
            };
            if (phNumC1 === '') {
                setIsPhNumC1Fill(false);
            };
            if (!comModeC1[0] || comModeC1[0].value === '') {
                setIsComModeC1Fill(false);
            };
            return false;
        }
    };

    const checkIfContact2IsDone = () => {
        if (contactsCount > 1) {
            if ((firstNameC2 !== '') && (lastNameC2 !== '') && (profC2[0] && profC2[0].value !== '') && (emailC2 !== '') && (phNumC2 !== '') && (comModeC2[0] && comModeC2[0].value !== '')) {
                resetC2ReqFills();
                return true;
            } else {
                resetC2ReqFills();
                if (firstNameC2 === '') {
                    setIsFirstNameC2Fill(false);
                };
                if (lastNameC2 === '') {
                    setIsLastNameC2Fill(false);
                };
                if (!profC2[0] || profC2[0].value === '') {
                    setIsProfC2Fill(false);
                };
                if (emailC2 === '') {
                    setIsEmailC2Fill(false);
                };
                if (phNumC2 === '') {
                    setIsPhNumC2Fill(false);
                };
                if (!comModeC2[0] || comModeC2[0].value === '') {
                    setIsComModeC2Fill(false);
                };
                return false;
            }
        } else {
            return true;
        }
    };

    const checkIfContact3IsDone = () => {
        if (contactsCount > 2) {
            if ((firstNameC3 !== '') && (lastNameC3 !== '') && (profC2[0] && profC2[0].value !== '') && (emailC3 !== '') && (phNumC3 !== '') && (comModeC3[0] && comModeC3[0].value !== '')) {
                resetC3ReqFills();
                return true;
            } else {
                resetC3ReqFills();
                if (firstNameC3 === '') {
                    setIsFirstNameC3Fill(false);
                };
                if (lastNameC3 === '') {
                    setIsLastNameC3Fill(false);
                };
                if (!profC3[0] || profC3[0].value === '') {
                    setIsProfC3Fill(false);
                };
                if (emailC3 === '') {
                    setIsEmailC3Fill(false);
                };
                if (phNumC3 === '') {
                    setIsPhNumC3Fill(false);
                };
                if (!comModeC3[0] || comModeC3[0].value === '') {
                    setIsComModeC3Fill(false);
                };
                return false;
            }
        } else {
            return true;
        }
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: true,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {

        const payload = {
            prefixC1: prefixC1[0] ? prefixC1[0].value : '',
            firstNameC1: firstNameC1,
            suffixC1: suffixC1[0] ? suffixC1[0].value : '',
            lastNameC1: lastNameC1,
            specC1: specC1,
            profC1: profC1[0] ? profC1[0].value : '',
            npiC1: npiC1,
            emailC1: emailC1,
            phNumC1: phNumC1,
            comModeC1: getStringFromSelectArray(comModeC1),

            prefixC2: prefixC2[0] ? prefixC2[0].value : '',
            firstNameC2: firstNameC2,
            suffixC2: suffixC2[0] ? suffixC2[0].value : '',
            lastNameC2: lastNameC2,
            specC2: specC2,
            profC2: profC2[0] ? profC2[0].value : '',
            npiC2: npiC2,
            emailC2: emailC2,
            phNumC2: phNumC2,
            comModeC2: getStringFromSelectArray(comModeC2),

            prefixC3: prefixC3[0] ? prefixC3[0].value : '',
            firstNameC3: firstNameC3,
            suffixC3: suffixC3[0] ? suffixC3[0].value : '',
            lastNameC3: lastNameC3,
            specC3: specC3,
            profC3: profC3[0] ? profC3[0].value : '',
            npiC3: npiC3,
            emailC3: emailC3,
            phNumC3: phNumC3,
            comModeC3: getStringFromSelectArray(comModeC3),
        };
        //console.log("careTeamInfo payload: ", payload);
        dispatch(setPRCareTeamInfo(payload));
    };

    const checkIfPageConditionsMet = () => {
        if (checkIfContact1IsDone() && checkIfContact2IsDone() && checkIfContact3IsDone()) {
            //console.log('conditions met!');
            setShowReqWarn(false);
            return true;
        } else {
            //console.log('conditions not met!');
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSaveAndContinue = () => {
        if (checkIfPageConditionsMet()) {
            props.tabChange("5");
            setTabInfo();
            markStepCompleted();
        }
    };

    const handleBackCLick = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: false,
            step4: false,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        props.tabChange("3");
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const editCareteamForm1 = (event) => {
        event.preventDefault();
        dispatch(setCareteamFormEdit1(true));
    };

    const editCareteamForm2 = (event) => {
        event.preventDefault();
        dispatch(setCareteamFormEdit2(true));
    };

    const editCareteamForm3 = (event) => {
        event.preventDefault();
        dispatch(setCareteamFormEdit3(true));
    };

    const cancelEdit1 = () => {
        dispatch(setCareteamFormEdit1(false));
    };

    const handleSaveCareteam1 = () => {

    };

    const cancelEdit2 = () => {
        dispatch(setCareteamFormEdit2(false));
    };

    const handleSaveCareteam2 = () => {

    };

    const cancelEdit3 = () => {
        dispatch(setCareteamFormEdit3(false));
    };

    const handleSaveCareteam3 = () => {

    };

    return (
        <>
            <div className={styles.containerDiv}>
                <div className={styles.dumDiv}>

                    {contactsCount >= 1 && (<>
                        <div className={styles.headerDiv}>
                            <div></div>
                            <div>Primary</div>
                            <div className={styles.editButtonDiv}>
                                {/* <div className={!isCareteamFormEditable1 ? styles.editButton : styles.editButtonClicked} onClick={event => editCareteamForm1(event)}>Edit</div> */}
                            </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Profession 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isCareteamFormEditable1 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isCareteamFormEditable1}
                                            values={profC1}
                                            onChange={setProfC1}
                                            options={optionsProf}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Speciality
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={specC1} onChange={(e) => setSpecC1(e.currentTarget.value)} disabled={!isCareteamFormEditable1} className={isCareteamFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isCareteamFormEditable1}
                                                className={isCareteamFormEditable1 ? styles.dropdownE : styles.dropdown}
                                                values={prefixC1}
                                                onChange={setPrefixC1}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name </div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC1} onChange={(e) => setFirstNameC1(e.currentTarget.value)} disabled={!isCareteamFormEditable1} className={isCareteamFormEditable1 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        NPI#
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={npiC1} onChange={(e) => setNpiC1(e.currentTarget.value)} disabled={!isCareteamFormEditable1} className={isCareteamFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name </div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC1} onChange={(e) => setLastNameC1(e.currentTarget.value)} disabled={!isCareteamFormEditable1} className={isCareteamFormEditable1 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isCareteamFormEditable1}
                                                className={isCareteamFormEditable1 ? styles.dropdownE : styles.dropdown}
                                                values={suffixC1}
                                                onChange={setSuffixC1}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isCareteamFormEditable1 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isCareteamFormEditable1}
                                            values={comModeC1}
                                            onChange={setComModeC1}
                                            options={optionsComMode}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC1} onChange={(e) => setPhNumC1(e.currentTarget.value)} disabled={!isCareteamFormEditable1} className={isCareteamFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC1} onChange={(e) => setEmailC1(e.currentTarget.value)} disabled={!isCareteamFormEditable1} className={isCareteamFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isCareteamFormEditable1 && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit1}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSaveCareteam1}>Save</div>
                        </div>)}
                        {(false && !showSecContact && !showTerContact) && (<>
                            <div className={styles.divFooter}>
                                <div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddSecContact(event)}>
                                        + Add Secondary
                                    </div>
                                </div>
                                {/* {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>} */}

                            </div>
                        </>)}
                    </>)}


                    {showSecContact && (<>
                        <div className={styles.headerDiv}>
                            <div></div>
                            <div>Secondary</div>
                            <div className={styles.editButtonDiv}>
                                {/* <div className={!isCareteamFormEditable2 ? styles.editButton : styles.editButtonClicked} onClick={event => editCareteamForm2(event)}>Edit</div> */}
                            </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Profession 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isCareteamFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isCareteamFormEditable2}
                                            values={profC2}
                                            onChange={setProfC2}
                                            options={optionsProf}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Speciality
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={specC2} onChange={(e) => setSpecC2(e.currentTarget.value)} disabled={!isCareteamFormEditable2} className={isCareteamFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isCareteamFormEditable2}
                                                className={isCareteamFormEditable2 ? styles.dropdownE : styles.dropdown}
                                                values={prefixC2}
                                                onChange={setPrefixC2}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name </div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC2} onChange={(e) => setFirstNameC2(e.currentTarget.value)} disabled={!isCareteamFormEditable2} className={isCareteamFormEditable2 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        NPI#
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={npiC2} onChange={(e) => setNpiC2(e.currentTarget.value)} disabled={!isCareteamFormEditable2} className={isCareteamFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name </div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC2} onChange={(e) => setLastNameC2(e.currentTarget.value)} disabled={!isCareteamFormEditable2} className={isCareteamFormEditable2 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isCareteamFormEditable2}
                                                className={isCareteamFormEditable2 ? styles.dropdownE : styles.dropdown}
                                                values={suffixC2}
                                                onChange={setSuffixC2}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isCareteamFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isCareteamFormEditable2}
                                            values={comModeC2}
                                            onChange={setComModeC2}
                                            options={optionsComMode}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC2} onChange={(e) => setPhNumC2(e.currentTarget.value)} disabled={!isCareteamFormEditable2} className={isCareteamFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC2} onChange={(e) => setEmailC2(e.currentTarget.value)} disabled={!isCareteamFormEditable2} className={isCareteamFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isCareteamFormEditable2 && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit2}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSaveCareteam2}>Save</div>
                        </div>)}
                        {(false && showSecContact && !showTerContact) && (<>
                            <div className={styles.divFooter}>
                                <div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddTerContact(event)}>
                                        + Add Tertiary
                                    </div>
                                </div>
                                {/* {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>} */}
                            </div>
                        </>)}
                    </>)}

                    {showTerContact && (<>
                        <div className={styles.headerDiv}>
                            <div></div>
                            <div>Tertiary</div>
                            <div className={styles.editButtonDiv}>
                                {/* <div className={!isCareteamFormEditable3 ? styles.editButton : styles.editButtonClicked} onClick={event => editCareteamForm3(event)}>Edit</div> */}
                            </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Profession 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isCareteamFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isCareteamFormEditable3}
                                            values={profC3}
                                            onChange={setProfC3}
                                            options={optionsProf}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Speciality
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={specC3} onChange={(e) => setSpecC3(e.currentTarget.value)} disabled={!isCareteamFormEditable3} className={isCareteamFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isCareteamFormEditable3}
                                                className={isCareteamFormEditable3 ? styles.dropdownE : styles.dropdown}
                                                values={prefixC3}
                                                onChange={setPrefixC3}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name </div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC3} onChange={(e) => setFirstNameC3(e.currentTarget.value)} disabled={!isCareteamFormEditable3} className={isCareteamFormEditable3 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        NPI#
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={npiC3} onChange={(e) => setNpiC3(e.currentTarget.value)} disabled={!isCareteamFormEditable3} className={isCareteamFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name </div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC3} onChange={(e) => setLastNameC3(e.currentTarget.value)} disabled={!isCareteamFormEditable3} className={isCareteamFormEditable3 ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isCareteamFormEditable3}
                                                className={isCareteamFormEditable3 ? styles.dropdownE : styles.dropdown}
                                                values={suffixC3}
                                                onChange={setSuffixC3}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isCareteamFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isCareteamFormEditable3}
                                            values={comModeC3}
                                            onChange={setComModeC3}
                                            options={optionsComMode}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC3} onChange={(e) => setPhNumC3(e.currentTarget.value)} disabled={!isCareteamFormEditable3} className={isCareteamFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address 
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC3} onChange={(e) => setEmailC3(e.currentTarget.value)} disabled={!isCareteamFormEditable3} className={isCareteamFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isCareteamFormEditable3 && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit3}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSaveCareteam3}>Save</div>
                        </div>)}

                        {showTerContact && (<>
                            <div className={styles.divFooter}>
                                {/* {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>} */}
                            </div>
                        </>)}
                    </>)}

                </div>
            </div>
        </>
    );
});

export default PatientCareteam;